import { useAppSelector } from 'app/hooks';
import { UserRoleTypes, WarehouseHistory } from 'app/types';
import { each, get } from 'lodash';
import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';

interface WarehouseHistoryCardProps {
	history: WarehouseHistory;
	type: string;
}

const WarehouseHistoryCard: React.FC<WarehouseHistoryCardProps> = ({ history, type }: WarehouseHistoryCardProps) => {
	const loggedUser = useAppSelector(state => state.auth.user);

	const calculateChangesAmount = () => {
		let sum = 0;
		each(history.products, p => {
			sum += p.amountAfter - p.amountBefore;
		});
		return sum > 0 ? `+${sum}` : sum;
	};

	return (
		<div className="card inwentaryzacja col-12 ps-0 mb-2 pe-4">
			<div className="row col-12 h-100 g-0 position-relative align-content-center">
				<Link
					to={`/warehouse/${type}/${history._id}`}
					className="col-11 col-lg-3 d-flex flex-column mb-lg-0 mb-3 ps-3 pe-3 justify-content-center"
				>
					{history.number}
				</Link>
				<div className="col-12 col-lg-3 d-flex flex-column pe-1 ps-5 py-3 mb-2 mb-lg-0 justify-content-center">
					<div className="text-alternate">{moment(history.createdAt).format('DD.MM.YYYY')}</div>
					<div className="text-small text-muted text-truncate position">
						{moment(history.createdAt).format('HH:mm')}
					</div>
				</div>
				<div className="col-12 col-lg-2 d-flex flex-column pe-1 mb-2 mb-lg-0 justify-content-center">
					<div className="text-alternate">{calculateChangesAmount()}</div>
				</div>
				<div className="col-12 col-lg-2 d-flex flex-column pe-1 mb-2 mb-lg-0 justify-content-center">
					<div className="text-alternate">{history.products.length}</div>
				</div>
				<div className="col-12 col-lg-2 d-flex pe-1 mb-2 mb-lg-0 align-items-center flex-wrap">
					<Link
						to={loggedUser?.role === UserRoleTypes.ADMIN ? `/users/${get(history, 'creator._id', '')}` : ''}
					>
						{get(history, 'creator.name', '-')}
					</Link>
				</div>
			</div>
		</div>
	);
};

export default WarehouseHistoryCard;
