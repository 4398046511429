import React from 'react';
import { css } from '@emotion/react';
import ClipLoader from 'react-spinners/ClipLoader';

interface ButtonComponentProps {
	onClick: any;
	title: string;
	isLoading?: boolean;
	classes?: string;
	id?: string;
}

const override = css`
	display: block;
	margin: 0 auto;
	border-color: green;
`;

const ButtonComponent: React.FC<ButtonComponentProps> = ({ id, onClick, title, isLoading, classes }) => {
	const __id = id ? { id } : {};
	const handleOnPress = () => {
		if (onClick instanceof Function && !isLoading) {
			onClick();
		}
	};
	return (
		<>
			<button
				type="button"
				onClick={() => handleOnPress()}
				className={classes || 'btn btn-primary w-100 px-3'}
				{...__id}
			>
				{!isLoading && <span>{title}</span>}
				<ClipLoader loading={isLoading} css={override} size={20} />
			</button>
		</>
	);
};

export default ButtonComponent;
