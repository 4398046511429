import { useAppSelector } from 'app/hooks';
import { ChangeStockProduct } from 'app/types';
import logoIcon from 'assets/images/logo.svg';
import searchIcon from 'assets/images/search.svg';
import PaginationComponent from 'components/Pagination.component';
import { find, findIndex, get, isArray, isEmpty } from 'lodash';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useGetShopStockQuery } from 'services/warehouses.service';
import WarehouseStockCard from './WarehouseStockCard.component';

interface WarehouseStockProps {
	editEnabled: boolean;
	setStockChanges: Dispatch<SetStateAction<ChangeStockProduct[]>>;
	stockChanges: ChangeStockProduct[];
}

const WarehouseStock: React.FC<WarehouseStockProps> = ({
	editEnabled,
	setStockChanges,
	stockChanges,
}: WarehouseStockProps) => {
	const selectedShop = useAppSelector(state => state.app.selectedShop);
	const [limit, setLimit] = useState(10);
	const [query, setQuery] = useState('');
	const [currentPage, setCurrentPage] = useState(1);

	const { data, refetch } = useGetShopStockQuery({
		id: selectedShop,
		limit,
		...(query.length > 2 ? { name: query } : {}),
		offset: (currentPage - 1) * limit,
		'sort[name]': 1,
	});

	useEffect(() => {
		setCurrentPage(1);
		refetch();
	}, [query, limit]);

	useEffect(() => {
		refetch();
	}, [currentPage]);

	const onStockAmountChange = (newStock: ChangeStockProduct) => {
		if (newStock.amount >= 0) {
			const newChanges = [...stockChanges];
			const productIndex = findIndex(newChanges, p => p.productId === newStock.productId);
			if (productIndex > -1) {
				newChanges[productIndex] = newStock;
			} else {
				newChanges.push(newStock);
			}
			setStockChanges(newChanges);
		}
	};

	const onStockAmountReset = (productId: string) => {
		const newChanges = [...stockChanges];
		const productIndex = findIndex(newChanges, p => p.productId === productId);
		if (productIndex > -1) {
			newChanges.splice(productIndex, 1);
			setStockChanges(newChanges);
		}
	};

	const renderList = () => {
		if (isArray(data?.documents) && !isEmpty(data?.documents)) {
			return data?.documents.map(product => {
				const productStockChanges = find(stockChanges, p => p.productId === product._id);
				return (
					<WarehouseStockCard
						product={product}
						key={product._id}
						editEnabled={editEnabled}
						stockChanges={productStockChanges}
						onStockAmountChange={onStockAmountChange}
						onStockAmountReset={onStockAmountReset}
					/>
				);
			});
		}
		return (
			<div className="empty-list col-sm-12 p-5 justify-content-center d-flex flex-wrap">
				<img src={logoIcon} />
				<h4 className="text-muted w-100 text-center mt-4">Brak produktów do wyświetlenia.</h4>
			</div>
		);
	};

	return (
		<div className="tab-pane fade show active" id="stanmagazynowy" role="tabpanel" aria-labelledby="pills-home-tab">
			<div className="row mb-2">
				<div className="col-sm-12 col-md-5 col-lg-3 col-xxl-2 mb-1">
					<div className="d-inline-block float-md-start me-1 mb-1 search-input-container w-100 shadow bg-white">
						<input
							className="form-control"
							placeholder="Szukaj"
							value={query}
							onChange={e => setQuery(e.target.value)}
						/>
						<span className="search-magnifier-icon">
							<img src={searchIcon} />
						</span>
					</div>
				</div>
				<div className="col-sm-12 col-md-7 col-lg-9 col-xxl-10 text-end d-flex justify-content-end mb-1">
					<select
						className="form-select shadow justify-self-end pe-5"
						aria-label="Pokaż"
						value={limit}
						onChange={(e: any) => setLimit(e.target.value)}
					>
						<option value="10">10 pozycji</option>
						<option value="25">25 pozycji</option>
						<option value="50">50 pozycji</option>
					</select>
				</div>
			</div>

			<div id="product-list" className="d-flex flex-wrap gx-4 gy-0">
				{get(data, 'total', 0) > 0 && (
					<div id="product-list-heading" className="g-0 mb-2 mb-lg-2 pe-4 d-flex col-12 align-content-center">
						<div className="col-3 col-lg-1 d-none d-lg-flex"> </div>
						<div className="col-3 col-lg-3 ps-3">
							<div className="text-muted text-small">NAZWA</div>
						</div>
						<div className="col-3 col-lg-3 ps-5">
							<div className="text-muted text-small">STAN</div>
						</div>
						<div className="col-2 col-lg-2">
							<div className="text-muted text-small">CENA NETTO</div>
						</div>
						<div className="col-2 col-lg-2">
							<div className="text-muted text-small">SPRZEDAŻ W TYM MIESIĄCU</div>
						</div>
						<div className="col-1 col-lg-1">
							<div className="text-muted text-small"></div>
						</div>
					</div>
				)}

				{renderList()}

				<div className="w-100 d-flex mt-2 justify-content-center">
					<PaginationComponent
						currentPage={currentPage}
						totalCount={data?.total || 0}
						pageSize={limit}
						onPageChange={setCurrentPage}
						siblingCount={2}
					/>
				</div>
			</div>
		</div>
	);
};

export default WarehouseStock;
