import { useAppSelector } from 'app/hooks';
import backIcon from 'assets/images/back.svg';
import listekIcon from 'assets/images/listek.svg';
import logoIcon from 'assets/images/logo.svg';
import PaginationComponent from 'components/Pagination.component';
import { get, isArray, isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useGetOrdersQuery } from 'services/orders.service';
import TransactionCard from './TransactionCard.component';

const TransactionsComponent: React.FC = () => {
	const selectedShop = useAppSelector(state => state.app.selectedShop);
	const [limit, setLimit] = useState(10);
	const [currentPage, setCurrentPage] = useState(1);

	const { data, refetch } = useGetOrdersQuery({
		shopId: selectedShop,
		limit,
		offset: (currentPage - 1) * limit,
		'sort[createdAt]': -1,
	});

	useEffect(() => {
		setCurrentPage(1);
		refetch();
	}, [limit]);

	useEffect(() => {
		refetch();
	}, [currentPage]);

	const renderList = () => {
		if (isArray(data?.documents) && !isEmpty(data?.documents)) {
			return data?.documents.map(order => <TransactionCard transaction={order} key={order._id} />);
		}
		return (
			<div className="empty-list col-sm-12 p-5 justify-content-center d-flex flex-wrap">
				<img src={logoIcon} />
				<h4 className="text-muted w-100 text-center mt-4">Brak transakcji do wyświetlenia.</h4>
			</div>
		);
	};

	return (
		<>
			<div className="row g-0">
				<div className="col-auto mb-3 me-auto">
					<div className="w-auto ">
						<Link to="/cockpit" className="muted-link pb-1 d-inline-block breadcrumb-back">
							<img src={backIcon} />
							&nbsp;
							<span className="text-small align-middle">Kokpit</span>
						</Link>
						<h1 className="mb-0 pb-0 d-flex flex-nowrap align-items-center" id="title">
							<img src={listekIcon} className="logo-leaf-heading mt-3 me-3" />
							Transakcje
						</h1>
					</div>
				</div>
			</div>
			<div className="d-flex flex-wrap mb-0">
				<hr className="w-100" />
			</div>

			<div id="transakcje">
				<div className="row mb-2">
					<div className="col-sm-12 col-lg-12 text-end d-flex justify-content-end mb-1">
						<select
							className="form-select shadow justify-self-end pe-5"
							aria-label="Pokaż"
							value={limit}
							onChange={(e: any) => setLimit(e.target.value)}
						>
							<option value="10">10 pozycji</option>
							<option value="25">25 pozycji</option>
							<option value="50">50 pozycji</option>
						</select>
					</div>
				</div>

				<div id="transaction-list" className="d-flex flex-wrap gx-4 gy-0">
					{get(data, 'total', 0) > 0 && (
						<div
							id="transaction-list-heading"
							className="g-0 mb-4 mb-lg-3 pe-4 d-flex col-12 align-content-center"
						>
							<div className="col-3 col-lg-2 ps-3">
								<div className="text-muted text-small">SPRZEDAŻ</div>
							</div>
							<div className="col-2 col-lg-2 ps-5">
								<div className="text-muted text-small">DATA</div>
							</div>
							<div className="col-2 col-lg-1">
								<div className="text-muted text-small">CZAS TRWANIA</div>
							</div>
							<div className="col-3 col-lg-1">
								<div className="text-muted text-small">PRODUKTY</div>
							</div>
							<div className="col-3 col-lg-2">
								<div className="text-muted text-small">SUMA</div>
							</div>
							<div className="col-3 col-lg-2">
								<div className="text-muted text-small">WYKONAWCA</div>
							</div>
							<div className="col-3 col-lg-2">
								<div className="text-muted text-small">STATUS</div>
							</div>
						</div>
					)}

					{renderList()}

					<div className="w-100 d-flex mt-2 justify-content-center">
						<PaginationComponent
							currentPage={currentPage}
							totalCount={data?.total || 0}
							pageSize={limit}
							onPageChange={setCurrentPage}
							siblingCount={2}
						/>
					</div>
				</div>
			</div>
		</>
	);
};

export default TransactionsComponent;
