import { useEffect, useState } from 'react';
import _, { get, has, isArray, isEmpty } from 'lodash';
import { Identity } from 'app/types';
import logoIcon from 'assets/images/logo.svg';
import UserToApproveCard from './UserToApproveCard.component';
import PaginationComponent from 'components/Pagination.component';
import { useGetIdentitiesQuery } from 'services/users.service';
import AdultVerificationModalComponent from './AdultVerificationModal.component';
import { useAppDispatch } from 'app/hooks';
import { setVerifyCount } from 'features/appSlice';

const UsersToApproveListComponent: React.FC = () => {
	const dispatch = useAppDispatch();
	const [verifyId, setVerifyId] = useState<string | null>();
	const [currentPage, setCurrentPage] = useState(1);
	const [limit, setLimit] = useState(10);
	const { data, refetch } = useGetIdentitiesQuery({
		limit,
		offset: (currentPage - 1) * limit,
		status: 'waiting',
		'sort[status]': 1,
	});

	useEffect(() => {
		if (data && has(data, 'total')) {
			dispatch(setVerifyCount({ verifyCount: data.total }));
		}
	}, [data]);

	useEffect(() => {
		setCurrentPage(1);
		refetch();
	}, [limit]);

	useEffect(() => {
		refetch();
	}, [currentPage]);

	const renderList = () => {
		if (isArray(data?.documents) && !isEmpty(data?.documents)) {
			return data?.documents.map((identity: Identity) => (
				<UserToApproveCard
					identity={identity}
					key={`identity-${identity._id}`}
					onVerifyClick={(id: string) => setVerifyId(id)}
				/>
			));
		}
		return (
			<div className="empty-list col-sm-12 p-5 justify-content-center d-flex flex-wrap">
				<img src={logoIcon} />
				<h4 className="text-muted w-100 text-center mt-4">Brak użytkowników do wyświetlenia.</h4>
			</div>
		);
	};

	const handleEndVerify = () => {
		setVerifyId(null);
		refetch();
	};

	return (
		<div className="tab-pane fade" id="customers-accept" role="tabpanel" aria-labelledby="customers-accept-tab">
			<div className="row mb-2">
				<div className="col-sm-12 col-lg-12 text-end d-flex justify-content-end mb-1">
					<select
						className="form-select shadow justify-self-end pe-5"
						aria-label="Pokaż"
						value={limit}
						onChange={e => setLimit(parseInt(e.target.value))}
					>
						<option value="10">10 pozycji</option>
						<option value="25">25 pozycji</option>
						<option value="50">50 pozycji</option>
					</select>
				</div>
			</div>

			<div id="customers-list" className="d-flex flex-wrap gx-4 gy-0">
				{get(data, 'total', 0) > 0 && (
					<div id="product-list-heading" className="g-0 mb-2 mb-lg-2 pe-4 d-flex col-12 align-content-center">
						<div className="col-3 col-lg-3 ps-3">
							<div className="text-muted text-small">NAZWA</div>
						</div>
						<div className="col-2 col-lg-3 ps-5">
							<div className="text-muted text-small">ADRES</div>
						</div>
						<div className="col-2 col-lg-2">
							<div className="text-muted text-small">KONTAKT</div>
						</div>
						<div className="col-3 col-lg-2">
							<div className="text-muted text-small">POTWIERDZENIE PEŁNOLETNIOŚCI</div>
						</div>
					</div>
				)}
			</div>

			{renderList()}

			<div className="w-100 d-flex mt-2 justify-content-center">
				<PaginationComponent
					currentPage={currentPage}
					totalCount={data?.total || 0}
					pageSize={limit}
					onPageChange={setCurrentPage}
					siblingCount={2}
				/>
			</div>

			<AdultVerificationModalComponent id={verifyId} onVerifyEnd={handleEndVerify} />
		</div>
	);
};

export default UsersToApproveListComponent;
