import React, { useState } from 'react';

interface TextareaComponentProps {
	id: string;
	placeholder?: string;
	label?: string;
	rows?: number;
	cols?: number;
	error?: string | undefined;
	register: any;
	classes: string;
	isSubmited?: boolean;
	labelClasses?: string;
}

const TextareaComponent: React.FC<TextareaComponentProps> = ({
	id,
	placeholder,
	label,
	rows,
	cols,
	error,
	register,
	classes,
	isSubmited,
	labelClasses,
}) => {
	const [hideError, setHideError] = useState(false);
	const inputWrapperClasses = `${classes} input-group`;
	const inputClasses = `form-control w-100 ${error ? 'is-invalid' : isSubmited && !error ? 'is-valid' : ''}`;
	const labelClass = labelClasses ? labelClasses : 'form-label';

	const handleFocus = () => {
		setHideError(true);
		document.getElementById(id)?.focus();
	};

	return (
		<div className={inputWrapperClasses}>
			{label && <label className={labelClass}>{label}</label>}
			<textarea
				{...register(id)}
				className={inputClasses}
				rows={rows}
				cols={cols}
				placeholder={placeholder}
				autoComplete="new-password"
				onFocus={() => handleFocus()}
				onBlur={() => setHideError(false)}
			/>
			{error && !hideError && (
				<div className="invalid-tooltip" onClick={() => setHideError(true)}>
					{error}
				</div>
			)}
		</div>
	);
};

export default TextareaComponent;
