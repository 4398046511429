import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from 'app/api';
import { LoginRequest, LoginResponse } from '../app/types';

export const authApi = createApi({
	baseQuery,
	endpoints: builder => ({
		login: builder.mutation<LoginResponse, LoginRequest>({
			query: credentials => ({
				url: 'webLogin',
				method: 'POST',
				body: credentials,
			}),
		}),
		isAuth: builder.query<LoginResponse, void>({
			query: () => ({
				url: 'checkAuth',
			}),
		}),
	}),
});

export const { useLoginMutation, useIsAuthQuery } = authApi;
