import { useAppSelector } from 'app/hooks';
import { Order, OrderStatusTypes, UserRoleTypes } from 'app/types';
import { get } from 'lodash';
import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';

interface TransactionCardProps {
	transaction: Order;
}

const TransactionCard: React.FC<TransactionCardProps> = ({ transaction }: TransactionCardProps) => {
	const user = useAppSelector(state => state.auth.user);

	const getStatusLabel = () => {
		switch (transaction.status) {
			case OrderStatusTypes.PAYMENT_ISSUED:
			case OrderStatusTypes.NEW:
				return <div className="payment-status bg-outline-yellow px-3 rounded-pill">W trakcie</div>;
			case OrderStatusTypes.COMPLETED:
				return <div className="payment-status bg-outline-green px-3 rounded-pill">Zatwierdzona</div>;
			case OrderStatusTypes.CANCELLED: {
				return <div className="payment-status bg-outline-red px-3 rounded-pill">Przerwana</div>;
			}
			default:
				return null;
		}
	};

	return (
		<div className="card sale selected col-12 ps-0 mb-3 pe-4">
			<div className="row col-12 h-100 g-0 position-relative align-content-center">
				<Link
					to={`/transaction/${transaction._id}`}
					className="col-11 col-lg-2 d-flex flex-column mb-lg-0 mb-3 ps-3 pe-3 justify-content-center"
				>
					{transaction.number}
				</Link>
				<div className="col-12 col-lg-2 d-flex flex-column pe-1 ps-5 py-3 mb-2 mb-lg-0 justify-content-center">
					<div>{moment(transaction.createdAt).format('DD.MM.YYYY')}</div>
					<div className="text-small text-muted text-truncate position">
						{moment(transaction.createdAt).format('HH:mm')}
					</div>
				</div>
				<div className="col-12 col-lg-1 d-flex flex-column pe-1 mb-2 mb-lg-0 justify-content-center">
					<div>1h 6min</div>
				</div>
				<div className="col-12 col-lg-1 d-flex flex-column pe-1 mb-2 mb-lg-0 justify-content-center">
					<div>{transaction.products.length}</div>
				</div>
				<div className="col-12 col-lg-2 d-flex pe-1 mb-2 mb-lg-0 align-items-center flex-wrap">
					<div className="text-alternate">{transaction.priceBrutto} zł</div>
				</div>
				<div className="col-12 col-lg-2 d-flex pe-1 mb-2 mb-lg-0 align-items-center flex-wrap">
					<Link
						to={user?.role === UserRoleTypes.ADMIN ? `/users/${get(transaction, 'creator._id', '')}` : ''}
					>
						{get(transaction, 'creator.name', '-')}
					</Link>
				</div>
				<div className="col-12 col-lg-2 d-flex pe-1 mb-2 mb-lg-0 align-items-center flex-wrap">
					{getStatusLabel()}
				</div>
			</div>
		</div>
	);
};

export default TransactionCard;
