import { ListParams } from './api';

export interface User {
	_id?: string;
	role: UserRoleTypes;
	verifiedAdult: boolean;
	verifiedEmail: boolean;
	verifiedPhone: boolean;
	active: boolean;
	createdAt: string;
	name: string;
	surname: string;
	address: Address;
	email: string;
	phone: string;
	commercialInformationAgreement: boolean;
	dataProcessingAgreement: boolean;
	shops: { _id: string; name: string }[];
	cardAdded?: boolean;
}

export interface Identity {
	_id: string;
	status: string;
	user: User;
	createdAt: string;
	frontPhoto: string;
	backPhoto: string;
	updatedAt?: string;
}

export interface SelectValueType {
	value: string;
	label: string;
}

export interface Address {
	_id?: string;
	houseNumber: string;
	street: string;
	country: string;
	locality: string;
	postCode: string;
	commune?: string;
	district?: string;
	voivodeship?: string;
}

export enum UserRoleTypes {
	ADMIN = 'admin',
	CUSTOMER = 'customer',
	OWNER = 'owner',
}

export interface LoginRequest {
	login: string;
	password: string;
}

export interface LoginResponse {
	success: boolean;
	user: User;
	accessToken: string;
}

export interface VerifyIdentityRequest {
	id: string;
	status: string;
	comment?: string;
}

export interface VerifyIdentityResponse {
	document: boolean;
	success: boolean;
}

export interface GetPhotoRequest {
	id: string;
}

export interface Product {
	_id: string;
	createdAt: string;
	deletedAt: string;
	updatedAt: string;
	creator: string;
	name: string;
	shopId: string;
	shortDescription: string;
	description: string;
	capacity: string;
	unitPriceNetto: number;
	tax: number;
	adultOnly: boolean;
	media: string[];
	active: boolean;
	ean: string;
	ingredients: string[];
}

export interface BaseSuccessResponse {
	success: boolean;
}

export interface Shop {
	_id: string;
	code: string;
	createdAt: string;
	name: string;
	owner: string;
	address: Address;
}

export interface BasePartialListResponse<T> {
	total: number;
	success: boolean;
	documents: Partial<T>[];
}

export interface BasePartialDocumentResponse<T> {
	success: boolean;
	document: Partial<T>;
}

export interface BaseListResponse<T> {
	total: number;
	success: boolean;
	documents: T[];
}

export interface BaseDocumentResponse<T> {
	success: boolean;
	document: T;
}

export interface GetProductRequest {
	id: string;
}

export interface GetShopRequest {
	id: string;
}

export interface GetUserRequest {
	id: string;
}

export interface CreateProductRequest {
	name: string;
	shopId: string;
	shortDescription: string;
	description: string;
	capacity: string;
	unitPriceNetto: number;
	tax: number;
	adultOnly: boolean;
	active: boolean;
	ean: string;
	ingredients: string[];
	media: string[];
	photos: any;
}

export interface CreateShopRequest {
	name: string;
	code: string;
	owner: string;
	selectedOwner?: string;
	address: Address;
}

export interface EditShopRequest {
	id: string;
	data: CreateShopRequest;
}

export interface UpdateProductRequest extends Partial<CreateProductRequest> {
	id: string;
}

export interface DeleteProductRequest {
	id: string;
}

export interface GetOrderRequest {
	id: string;
}
export interface CreateUserRequest {
	name: string;
	surname: string;
	active: boolean;
	verifiedAdult: boolean;
	commercialInformationAgreement: boolean;
	dataProcessingAgreement: boolean;
	role: string;
	address: Address;
	password?: string;
	passwordConfirm?: string;
}

export interface UpdateUserRequest extends Partial<CreateUserRequest> {
	id: string;
}

export interface DeleteUserRequest {
	id: string;
}

export enum OrderStatusTypes {
	NEW = 'new',
	CANCELLED = 'cancelled',
	PAYMENT_ISSUED = 'payment_issued',
	COMPLETED = 'completed',
}

export interface OrderProduct {
	productId: string;
	amount: number;
	unitpriceNetto: number;
	tax: number;
	unitPriceBrutto: number;
	priceNettoSum: number;
	priceBruttoSum: number;
	name: string;
	capacity: string;
	ean: string;
}

export interface Order {
	_id: string;
	createdAt: string;
	deletedAt: string;
	updatedAt: string;
	creator: string;
	shopId: string;
	number: string;
	status: OrderStatusTypes;
	products: OrderProduct[];
	priceNetto: number;
	priceBrutto: number;
	cardNumberMasked: string;
}

export interface WarehouseProduct {
	productId: string;
	amount: number;
}

export interface Warehouse {
	shopId: string;
	products: WarehouseProduct[];
}

export enum ChangeStockAction {
	ADD = 'add',
	SUBTRACT = 'subtract',
}

export interface ChangeStockProduct {
	productId: string;
	action: ChangeStockAction;
	amount: number;
}

export interface ChangeStockRequest {
	id: string;
	products: ChangeStockProduct[];
}

export interface GetWarehouseRequest {
	id: string;
}

export interface GetStockProduct extends Product {
	amount: number;
	monthlySale: number;
}

export interface GetStockRequest extends ListParams {
	id: string;
}

export interface WarehouseHistoryProduct {
	productId: string;
	amountBefore: number;
	amountAfter: number;
}

export enum WarehouseHistoryAction {
	MANAGEMENT = 'management',
	INVENTORY = 'inventory',
	ORDER = 'order',
}

export interface WarehouseHistory {
	_id: string;
	number: string;
	createdAt: string;
	warehouseId: string;
	products: WarehouseHistoryProduct[];
	action: WarehouseHistoryAction;
	refId: string;
}

export interface GetWarehouseHistoryRequest {
	id: string;
}

export enum UserRoleTranslation {
	customer = 'Klient',
	owner = 'Właściciel',
	admin = 'Administrator',
}

export interface CreateAdminRequest {
	edit?: boolean;
	name: string;
	surname: string;
	email: string;
	role: string;
	password?: string;
	passwordConfirm?: string;
	active?: boolean;
}

export interface UpdateAdminRequest extends Partial<CreateAdminRequest> {
	id: string;
}
