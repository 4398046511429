import { yupResolver } from '@hookform/resolvers/yup';
import shopSchema from 'app/schemas/shop.schema';
import { CreateShopRequest, User, SelectValueType, BaseDocumentResponse, Shop } from 'app/types';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useCreateShopMutation, useLazyGetShopQuery, useEditShopMutation } from 'services/shops.service';
import { useLazyGetUsersByRoleQuery } from 'services/users.service';
import { isArray, isEmpty } from 'lodash';
import { useAppDispatch } from 'app/hooks';
import TextInputComponent from 'components/TextInput.component';
import { setToast, ToastType } from 'features/appSlice';

import backIcon from 'assets/images/back.svg';
import listekIcon from 'assets/images/listek.svg';
import saveIcon from 'assets/images/save.svg';
import plusIcon from 'assets/images/plus.svg';
import userIcon from 'assets/images/user.svg';
import deleteIcon from 'assets/images/delete.svg';
import { translateError } from 'app/util';

const ShopForm: React.FC = () => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const { id } = useParams();
	const [selectedOwner, setSelectedOwner] = useState<SelectValueType>();
	const [owners, setOwners] = useState<SelectValueType[]>([]);
	const [createShop] = useCreateShopMutation();
	const [editShop] = useEditShopMutation();
	const [getShop] = useLazyGetShopQuery();
	const [getUsersByRole] = useLazyGetUsersByRoleQuery();

	const {
		register,
		handleSubmit,
		resetField,
		setValue,
		getValues,
		formState: { errors, isSubmitted },
	} = useForm<CreateShopRequest>({
		resolver: yupResolver(shopSchema),
		reValidateMode: 'onChange',
	});

	useEffect(() => {
		const loadOwners = async () => {
			const data = await getUsersByRole('owner').unwrap();

			if (isArray(data?.documents) && !isEmpty(data?.documents)) {
				const ownersToDisplay: SelectValueType[] = [];

				data?.documents.forEach((user: User) => {
					ownersToDisplay.push({ value: user._id || '', label: user.name + ' ' + user.surname });
				});

				setOwners(ownersToDisplay);
			}
		};

		loadOwners();
	}, []);

	useEffect(() => {
		const loadProduct = async () => {
			try {
				if (id) {
					const res = await getShop({ id }).unwrap();

					if (res.success && res.document) {
						if ('name' in res.document) {
							setValue('name', res.document.name);
						}
						if ('code' in res.document) {
							setValue('code', res.document.code);
						}
						if ('owner' in res.document && !isEmpty(owners)) {
							const index = owners.findIndex(
								(user: SelectValueType) => user.value === res.document.owner,
							);

							if (index > -1) {
								setSelectedOwner(owners[index]);
								setValue('owner', res.document.owner);
							}
						}
						if ('address' in res.document) {
							if ('postCode' in res.document.address) {
								setValue('address.postCode', res.document.address.postCode);
							}
							if ('locality' in res.document.address) {
								setValue('address.locality', res.document.address.locality);
							}
							if ('houseNumber' in res.document.address) {
								setValue('address.houseNumber', res.document.address.houseNumber);
							}
							if ('street' in res.document.address) {
								setValue('address.street', res.document.address.street);
							}
						}
					}
				}
			} catch (err) {
				dispatch(setToast({ type: ToastType.WARNING }));
			}
		};
		loadProduct();
	}, [id, owners]);

	const handleSave = async (data: CreateShopRequest) => {
		if (selectedOwner?.value && data) {
			data.owner = selectedOwner.value;
		} else if (data) {
			data.owner = '';
		}

		try {
			let res: BaseDocumentResponse<Shop>;

			if (id) {
				const req = editShop;
				res = await req({ id, data }).unwrap();
			} else {
				const req = createShop;
				res = await req(data).unwrap();
			}

			if (res?.success) {
				dispatch(setToast({ type: ToastType.SUCCESS }));
				navigate('/shops');
			}
		} catch (err) {
			dispatch(setToast({ type: ToastType.WARNING }));
		}
	};

	const handleSelectOwner = (selectedOwnerValue: string) => {
		const index = owners.findIndex((owner: SelectValueType) => owner.value === selectedOwnerValue);

		if (index > -1) {
			setSelectedOwner(owners[index]);
		}
	};

	const handleResetOwner = () => {
		resetField('owner');
		setSelectedOwner(undefined);
	};

	return (
		<form onSubmit={handleSubmit(handleSave)}>
			<div className="row g-0">
				<div className="col-sm-6 mb-3 me-auto">
					<div className="w-auto">
						<a href="/shops.html" className="muted-link pb-1 d-inline-block breadcrumb-back">
							<img src={backIcon} />
							<span className="text-small text-muted align-middle">Sklepy</span>
						</a>
						<h1 className="mb-0 pb-0 " id="title">
							<img src={listekIcon} className="logo-leaf-heading mt-3 me-3" />
							{id ? 'Edytuj sklep' : 'Dodaj sklep'}
						</h1>
					</div>
				</div>
				<div className="col-12 col-sm-2 d-flex align-items-end justify-content-end mb-3 order-sm-3">
					<button
						type="submit"
						className="semigreen btn btn-outline-primary border-0 w-100 px-3"
						data-bs-toggle="tooltip"
						data-bs-placement="top"
						title="Zapisz"
						data-bs-original-title="Tooltip on top"
					>
						<img src={saveIcon} width="20" height="20" className="m-2" />
					</button>
				</div>
			</div>
			<div id="customer-details" className="row gx-4 gy-0">
				<div className="col-sm-8 col-md-8 col-sm-12 ">
					<h5 className="text-alternate mt-3 mb-2 ps-3">Dane</h5>
					<div className="card">
						<div className="card-body">
							<div className="row gx-4 gy-0">
								<div className="col-sm-12 col-md-6">
									<TextInputComponent
										label="Nazwa"
										type="text"
										register={register}
										id="name"
										isSubmited={isSubmitted}
										error={errors.name?.message}
										classes="mb-3"
									/>
								</div>
								<div className="col-sm-12 col-md-6">
									<TextInputComponent
										label="Kod"
										type="text"
										register={register}
										id="code"
										isSubmited={isSubmitted}
										error={errors.code?.message}
										classes="mb-3"
									/>
								</div>
							</div>
							<div className="row gx-4 gy-0">
								<div className="col-sm-12 col-md-3">
									<TextInputComponent
										label="Kod pocztowy"
										type="text"
										register={register}
										id="address.postCode"
										isSubmited={isSubmitted}
										error={errors.address?.postCode?.message}
										classes="mb-3"
									/>
								</div>
								<div className="col-sm-12 col-md-3">
									<TextInputComponent
										label="Miejscowość"
										type="text"
										register={register}
										id="address.locality"
										isSubmited={isSubmitted}
										error={errors.address?.locality?.message}
										classes="mb-3"
									/>
								</div>
								<div className="col-sm-12 col-md-3">
									<TextInputComponent
										label="Ulica"
										type="text"
										register={register}
										id="address.street"
										isSubmited={isSubmitted}
										error={errors.address?.street?.message}
										classes="mb-3"
									/>
								</div>
								<div className="col-sm-12 col-md-3">
									<TextInputComponent
										label="Numer"
										type="text"
										register={register}
										id="address.houseNumber"
										isSubmited={isSubmitted}
										error={errors.address?.houseNumber?.message}
										classes="mb-3"
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="col-md-4 col-sm-12 ">
					<h5 className="text-alternate mt-3 mb-2 ps-3">Administratorzy</h5>
					<div className="card">
						<div className="card-body">
							{selectedOwner && (
								<div className="admin-shop d-flex justify-content-between align-items-center">
									<div className="text-alternate green">
										<img src={userIcon} /> {selectedOwner.label}
									</div>
									<button
										type="button"
										className="btn btn-outline-danger px-3 m-1 border-0"
										data-bs-toggle="tooltip"
										data-bs-placement="top"
										title="Usuń"
										data-bs-original-title="Tooltip on top"
									>
										<img src={deleteIcon} onClick={handleResetOwner} />
									</button>
								</div>
							)}
							<div className="shopselector d-flex justify-content-center">
								<select
									defaultValue={selectedOwner?.value}
									className={`shop-select w-50 text-center ${errors?.owner && 'is-invalid'}`}
									{...register('owner')}
									id="owner"
								>
									<option value="">Wybierz administratora</option>
									{owners.map((owner: SelectValueType, key: number) => (
										<option key={`owner-${key}`} value={owner.value}>
											{owner.label}
										</option>
									))}
								</select>
								<button
									type="button"
									className="btn btn-outline-primary border-0 w-50 m-0 ms-3 px-3"
									onClick={() => handleSelectOwner(getValues('owner'))}
								>
									<img src={plusIcon} />
									<span> &nbsp; Przydziel</span>
								</button>
							</div>
						</div>
						{errors.owner ? (
							<p className="text-danger text-center">{translateError(errors.owner.message)}</p>
						) : null}
					</div>
				</div>
			</div>
		</form>
	);
};

export default ShopForm;
