/* eslint-disable unused-imports/no-unused-imports */
import { WarehouseHistoryAction } from 'app/types';
import logoIcon from 'assets/images/logo.svg';
import PaginationComponent from 'components/Pagination.component';
import { get, isArray, isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { useGetWarehouseHistoryListQuery } from 'services/warehouses.service';
import WarehouseHistoryCard from './WarehouseHistoryCard.component';

interface WarehouseCorrectionsProps {
	warehouseId: string;
}

const WarehouseCorrections: React.FC<WarehouseCorrectionsProps> = ({ warehouseId }: WarehouseCorrectionsProps) => {
	const [limit, setLimit] = useState(10);
	const [currentPage, setCurrentPage] = useState(1);

	const { data, refetch } = useGetWarehouseHistoryListQuery({
		warehouseId,
		action: WarehouseHistoryAction.MANAGEMENT,
		limit,
		offset: (currentPage - 1) * limit,
		'sort[createdAt]': -1,
	});

	useEffect(() => {
		setCurrentPage(1);
		refetch();
	}, [limit]);

	useEffect(() => {
		refetch();
	}, [currentPage]);

	const renderList = () => {
		if (isArray(data?.documents) && !isEmpty(data?.documents)) {
			return data?.documents.map(history => (
				<WarehouseHistoryCard key={history._id} history={history} type="correction" />
			));
		}
		return (
			<div className="empty-list col-sm-12 p-5 justify-content-center d-flex flex-wrap">
				<img src={logoIcon} />
				<h4 className="text-muted w-100 text-center mt-4">Brak historii do wyświetlenia.</h4>
			</div>
		);
	};

	return (
		<div className="tab-pane fade" id="korekty" role="tabpanel" aria-labelledby="korekty-tab">
			<div className="row mb-2">
				<div className="col-sm-12 col-md-5 col-lg-3 col-xxl-2 mb-1"></div>
				<div className="col-sm-12 col-md-7 col-lg-9 col-xxl-10 text-end d-flex justify-content-end mb-1">
					<select
						className="form-select shadow justify-self-end pe-5"
						aria-label="Pokaż"
						value={limit}
						onChange={(e: any) => setLimit(e.target.value)}
					>
						<option value="10">10 pozycji</option>
						<option value="25">25 pozycji</option>
						<option value="50">50 pozycji</option>
					</select>
				</div>
			</div>
			<div id="product-list" className="d-flex flex-wrap gx-4 gy-0">
				{get(data, 'total', 0) > 0 && (
					<div id="product-list-heading" className="g-0 mb-2 mb-lg-2 pe-4 d-flex col-12 align-content-center">
						<div className="col-3 col-lg-3 ps-3">
							<div className="text-muted text-small">KOREKTA RĘCZNA</div>
						</div>
						<div className="col-2 col-lg-3 ps-5">
							<div className="text-muted text-small">DATA</div>
						</div>
						<div className="col-2 col-lg-2">
							<div className="text-muted text-small">SUMA ZMIAN</div>
						</div>
						<div className="col-3 col-lg-2">
							<div className="text-muted text-small">ILOŚĆ PRODUKTÓW</div>
						</div>
						<div className="col-3 col-lg-2">
							<div className="text-muted text-small">WYKONAWCA</div>
						</div>
					</div>
				)}

				{renderList()}

				<div className="w-100 d-flex mt-2 justify-content-center">
					<PaginationComponent
						currentPage={currentPage}
						totalCount={data?.total || 0}
						pageSize={limit}
						onPageChange={setCurrentPage}
						siblingCount={2}
					/>
				</div>
			</div>
		</div>
	);
};

export default WarehouseCorrections;
