import { ChangeStockAction, ChangeStockProduct, GetStockProduct } from 'app/types';
import { get } from 'lodash';
import React from 'react';
import { Link } from 'react-router-dom';
import historyIcon from 'assets/images/history.svg';

interface WarehouseStockCardProps {
	product: GetStockProduct;
	editEnabled: boolean;
	stockChanges?: ChangeStockProduct;
	onStockAmountChange: (newStock: ChangeStockProduct) => void;
	onStockAmountReset: (productId: string) => void;
}

const WarehouseStockCard: React.FC<WarehouseStockCardProps> = ({
	product,
	editEnabled,
	stockChanges,
	onStockAmountChange,
	onStockAmountReset,
}: WarehouseStockCardProps) => {
	const renderChangeBadge = () => {
		if (stockChanges) {
			switch (stockChanges.action) {
				case ChangeStockAction.ADD: {
					return (
						<div className="text-muted text-small me-auto ms-2 align-self-center text-success badge rounded-pill bg-outline-green">
							+{stockChanges.amount}
						</div>
					);
				}
				case ChangeStockAction.SUBTRACT: {
					return (
						<div className="text-muted text-small me-auto ms-2 align-self-center text-danger badge rounded-pill bg-outline-red">
							-{stockChanges.amount}
						</div>
					);
				}
				default:
					return null;
			}
		}
		return null;
	};

	const handleStockChange = (newAmount: number) => {
		if (!isNaN(newAmount) && newAmount >= 0) {
			const diff = newAmount - product.amount;
			onStockAmountChange(
				Object.assign({}, stockChanges, {
					amount: Math.abs(diff),
					productId: product._id,
					action: diff > 0 ? 'add' : 'subtract',
				}),
			);
		}
	};

	const getChangeValue = () => {
		if (!stockChanges) return product.amount;
		if (stockChanges.amount === 0) {
			//using on onStockAmountReset directly throws error so click instead
			document.getElementById(`${product._id}reset`)?.click();
			return 0;
		}
		if (stockChanges.action === ChangeStockAction.SUBTRACT) {
			return product.amount - stockChanges.amount;
		} else {
			return product.amount + stockChanges.amount;
		}
	};

	return (
		<div className="card product col-12 ps-0 mb-2 pe-4">
			<div className="row col-12 h-100 g-0 position-relative align-content-center">
				<Link to={`/product/${product._id}`} className="col-1">
					<img
						src={get(product, 'media[0]', '')}
						alt="product"
						className="card-img card-img-horizontal h-100"
					/>
				</Link>
				<Link
					to={`/product/${product._id}`}
					className="col-3 col-lg-3 d-flex flex-column mb-lg-0 mb-3 ps-3 pe-3 justify-content-center"
				>
					{product.name}
					<div className="text-small text-muted text-truncate position">EAN: {product.ean}</div>
				</Link>
				{editEnabled && (
					<div className="col-3 col-lg-3 d-flex pe-1 mb-2 mb-lg-0 justify-content-start align-content-center h-100">
						<div className="input-group w-auto align-content-center">
							<input
								type="button"
								value="-"
								className="button-minus"
								data-field="quantity"
								onClick={() => handleStockChange(getChangeValue() - 1)}
							/>
							<input
								type="number"
								step="1"
								min="0"
								max=""
								value={getChangeValue()}
								onChange={e => handleStockChange(parseInt(e.target.value))}
								name="quantity"
								className="quantity-field p-1 m-0"
							/>
							<input
								type="button"
								value="+"
								className="button-plus"
								data-field="quantity"
								onClick={() => handleStockChange(getChangeValue() + 1)}
							/>
						</div>
						{renderChangeBadge()}
					</div>
				)}
				{!editEnabled && (
					<div className="col-3 col-lg-3 d-flex flex-column pe-1 ps-5 mb-2 mb-lg-0 justify-content-center">
						<div className="text-alternate">{product.amount}</div>
					</div>
				)}
				<div className="col-2 col-lg-2 d-flex flex-column pe-1 mb-2 mb-lg-0 justify-content-center">
					<div>{product.unitPriceNetto} zł</div>
				</div>
				<div className="col-2 col-lg-2 d-flex flex-column pe-1 mb-2 mb-lg-0 justify-content-center">
					<div className="text-alternate">14</div>
				</div>
				<div className="col-1 col-lg-1 d-flex pe-1 mb-2 mb-lg-0 align-items-center justify-content-end flex-wrap">
					{editEnabled && stockChanges && (
						<button
							type="button"
							className="btn btn-outline-primary px-3 m-1 border-0"
							id={`${product._id}reset`}
							onClick={() => onStockAmountReset(product._id)}
						>
							<img
								src={historyIcon}
								alt="Resetuj zmiany"
								data-bs-toggle="tooltip"
								data-bs-placement="top"
								title="Resetuj zmiany"
								data-bs-original-title="Resetuj zmiany"
							/>
						</button>
					)}
				</div>
			</div>
		</div>
	);
};

export default WarehouseStockCard;
