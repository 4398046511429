import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import appReducer from 'features/appSlice';
import authReducer from 'features/authSlice';
import { authApi } from 'services/auth.service';
import { productsApi } from 'services/products.service';
import { shopsApi } from 'services/shops.service';
import { ordersApi } from 'services/orders.service';
import { usersApi } from 'services/users.service';
import { warehousesApi } from 'services/warehouses.service';
import { cockpitApi } from 'services/cockpit.service';

const customizedMiddleware = getDefaultMiddleware({
	serializableCheck: false,
});

export const store = configureStore({
	reducer: {
		app: appReducer,
		auth: authReducer,
		[authApi.reducerPath]: authApi.reducer,
		[productsApi.reducerPath]: productsApi.reducer,
		[shopsApi.reducerPath]: shopsApi.reducer,
		[usersApi.reducerPath]: usersApi.reducer,
		[ordersApi.reducerPath]: ordersApi.reducer,
		[warehousesApi.reducerPath]: warehousesApi.reducer,
		[cockpitApi.reducerPath]: cockpitApi.reducer,
	},
	middleware: customizedMiddleware
		.concat(authApi.middleware)
		.concat(productsApi.middleware)
		.concat(shopsApi.middleware)
		.concat(usersApi.middleware)
		.concat(ordersApi.middleware)
		.concat(warehousesApi.middleware)
		.concat(cockpitApi.middleware),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
