import { useAppSelector } from 'app/hooks';
import LogoIcon from './icons/logo.icon';

const AppLoaderComponent: React.FC = () => {
	const appIsLoading = useAppSelector(state => state.app.isLoading);

	return (
		<>
			{appIsLoading && (
				<div id="app-loader">
					<div className="icon-wrapper">
						<LogoIcon />
					</div>
				</div>
			)}
		</>
	);
};

export default AppLoaderComponent;
