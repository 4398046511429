import React from 'react';

interface SwitchComponentProps {
	id: string;
	label: string;
	error?: string | undefined;
	register: any;
	classes: string;
}

const SwitchComponent: React.FC<SwitchComponentProps> = ({ id, label, error, register, classes }) => {
	return (
		<>
			<div className={classes || 'form-check form-switch mt-1'}>
				<input className="form-check-input" type="checkbox" id={id} {...register(id)} />
				<label className="form-check-label" htmlFor={id}>
					{label}
				</label>
			</div>
			{error && <div className="invalid-tooltip">{error}</div>}
		</>
	);
};

export default SwitchComponent;
