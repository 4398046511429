import { useAppDispatch, useAppSelector } from 'app/hooks';
import { SelectValueType, Shop, UserRoleTypes } from 'app/types';
import { setSelectedShop } from 'features/appSlice';
import { removeCredentials } from 'features/authSlice';
import { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useLazyGetShopsQuery } from 'services/shops.service';
import ArrowDownIcon from './icons/arrowDown.icon';
import CockpitIcon from './icons/cockpit.icon';
import CompaniesIcon from './icons/companies.icon';
import LogoutIcon from './icons/logout.icon';
import LogoWhiteIcon from './icons/logoWhite.icon';
import OCLogoIcon from './icons/oclogo.icon';
import ProductIcon from './icons/product.icon';
import TransactionIcon from './icons/transaction.icon';
import WarehouseIcon from './icons/warehouse.icon';

const SidebarComponent: React.FC = () => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const location = useLocation();
	const user = useAppSelector(state => state.auth.user);
	const verifyCount = useAppSelector(state => state.app.verifyCount);
	const selectedShop = useAppSelector(state => state.app.selectedShop);
	const [shopsToDisplay, setShopsToDisplay] = useState<SelectValueType[]>([]);

	const [getUserShops] = useLazyGetShopsQuery();

	const handleLogout = () => {
		dispatch(removeCredentials());
		dispatch(setSelectedShop({ id: '' }));
		setShopsToDisplay([]);
		navigate('/login');
	};

	useEffect(() => {
		const loadShops = async () => {
			const data = await getUserShops({}).unwrap();
			const tmpShopsToDisplay: SelectValueType[] = [];
			const savedShop = localStorage.getItem('@selectedShop');
			let shopWasSet = false;
			data?.documents.forEach((shop: Shop, index: number) => {
				if ((!savedShop && index === 0) || savedShop === shop._id) {
					shopWasSet = true;
					dispatch(setSelectedShop({ id: shop._id }));
				}
				tmpShopsToDisplay.push({ value: shop._id || '', label: shop.name });
			});
			if (!shopWasSet) {
				dispatch(setSelectedShop({ id: data?.documents[0]._id }));
			}
			setShopsToDisplay(tmpShopsToDisplay);
		};
		loadShops();
	}, []);

	const handleChangeUserTab = (id: string) => {
		setTimeout(() => document.getElementById(id)?.click(), 100);
	};

	return (
		<nav id="sidebar">
			<div className="p-0 pt-5 d-flex h-100 flex-column align-items-center justify-content-between">
				<div className="w-100">
					<Link to="/cockpit" className="img logo mb-4">
						<LogoWhiteIcon />
					</Link>
					<div className="shopselector d-flex justify-content-center">
						<select
							value={selectedShop}
							className="shop-select"
							onChange={e => dispatch(setSelectedShop({ id: e.target.value }))}
						>
							{shopsToDisplay.map((shop: SelectValueType, index: number) => {
								if (index === 0) {
									return (
										<option defaultValue={shop.value} key={`shop-option-${shop.value}`}>
											{shop.label}
										</option>
									);
								}
								return (
									<option value={shop.value} key={`shop-option-${shop.value}`}>
										{shop.label}
									</option>
								);
							})}
						</select>
					</div>
					<hr />
					<ul className="list-unstyled components mb-5">
						<li className={location.pathname.includes('cockpit') ? 'active' : ''}>
							<Link to="cockpit">
								<CockpitIcon /> Kokpit
							</Link>
						</li>
						{user?.role === UserRoleTypes.ADMIN && (
							<li className={location.pathname.includes('user') ? 'active' : ''}>
								<a
									href="#users-submenu"
									data-bs-toggle="collapse"
									role="button"
									aria-expanded="false"
									aria-controls="collapseExample"
									className="w-100 pe-2"
								>
									<CompaniesIcon /> Użytkownicy
									<div className="menu-submenu-ico float-end">
										<ArrowDownIcon />
									</div>
								</a>
								<ul className="collapse list-unstyled" id="users-submenu">
									<li onClick={() => handleChangeUserTab('customers-tab')}>
										<Link to="/users">Wszyscy</Link>
									</li>
									<li onClick={() => handleChangeUserTab('pills-profile-tab')}>
										<Link to="/users">
											Do zatwierdzenia <span className="badge bg-secondary">{verifyCount}</span>
										</Link>
									</li>
								</ul>
							</li>
						)}
						<li className={location.pathname.includes('product') ? 'active' : ''}>
							<Link to="/products">
								<ProductIcon /> Produkty
							</Link>
						</li>
						<li className={location.pathname.includes('warehouse') ? 'active' : ''}>
							<Link to="/warehouse">
								<WarehouseIcon /> Magazyn
							</Link>
						</li>
						<li className={location.pathname.includes('transaction') ? 'active' : ''}>
							<Link to="/transactions">
								<TransactionIcon />
								Transakcje
							</Link>
						</li>
						{user?.role === UserRoleTypes.ADMIN && (
							<li className={location.pathname.includes('shop') ? 'active' : ''}>
								<Link to="/shops">
									<span className="logoleaf"></span> Sklepy
								</Link>
							</li>
						)}
					</ul>
				</div>

				<div className="footer pb-4 w-100">
					<ul className="list-unstyled components mb-2">
						<li>
							<div onClick={handleLogout}>
								<LogoutIcon /> Wyloguj się
							</div>
						</li>
					</ul>
					<hr />
					<div className=" text-center">
						by
						<a href="https://over-cloud.pl/" rel="noreferrer" target="_blank" title="Software house Łódź">
							<OCLogoIcon />
						</a>
					</div>
				</div>
			</div>
		</nav>
	);
};

export default SidebarComponent;
