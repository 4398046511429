import { useAppDispatch, useAppSelector } from 'app/hooks';
import { UserRoleTypes, WarehouseHistory, WarehouseHistoryProduct } from 'app/types';
import backIcon from 'assets/images/back.svg';
import closeIcon from 'assets/images/close.svg';
import listekIcon from 'assets/images/listek.svg';
import { setIsLoading as setGlobalLoader } from 'features/appSlice';
import { get, isEmpty, isString } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useGetWarehouseHistoryQuery } from 'services/warehouses.service';

const WarehouseHistoryDetails: React.FC = () => {
	const { id, type } = useParams();
	const dispatch = useAppDispatch();
	const loggedUser = useAppSelector(state => state.auth.user);
	const [history, setHistory] = useState<WarehouseHistory | null>(null);
	const { data, isLoading } = useGetWarehouseHistoryQuery({ id: isString(id) ? id : '' });

	useEffect(() => {
		dispatch(setGlobalLoader({ isLoading }));
	}, [isLoading]);

	useEffect(() => {
		if (data && data.document) {
			setHistory(data.document);
		}
	}, [data]);

	const renderChangeBadge = (product: WarehouseHistoryProduct) => {
		const diff = product.amountAfter - product.amountBefore;
		if (diff > 0) {
			return <div className="ps-2 badge rounded-pill bg-outline-green me-auto">+{diff}</div>;
		}
		return <div className="ps-2 badge rounded-pill bg-outline-red me-auto">{diff}</div>;
	};

	const renderList = () => {
		if (!isEmpty(history?.products)) {
			return history?.products.map((product, index) => (
				<div className="card product col-12 ps-0 mb-2 pe-4" key={index + product.productId}>
					<div className="row col-12 h-100 g-0 position-relative align-content-center">
						<Link to={`/product/${get(product, 'productId._id', '')}`} className="col-1">
							<img
								src={get(product, 'productId.media[0]', closeIcon)}
								alt="product"
								className="card-img card-img-horizontal h-100"
							/>
						</Link>
						<Link
							to={`/product/${get(product, 'productId._id', '')}`}
							className="col-11 col-lg-3 d-flex flex-column mb-lg-0 mb-3 ps-3 pe-3 justify-content-center"
						>
							{get(product, 'productId.name', '[Produkt usunięty]')}
							<div className="text-small text-muted text-truncate position">
								EAN: {get(product, 'productId.ean', '-')}
							</div>
						</Link>
						<div className="col-12 col-lg-2 d-flex flex-column pe-1 ps-5 mb-2 mb-lg-0 justify-content-center">
							<div>{product.amountBefore}</div>
						</div>
						<div className="col-12 col-lg-2 d-flex flex-column pe-1 mb-2 mb-lg-0 justify-content-center">
							<div>{product.amountAfter}</div>
						</div>
						<div className="col-12 col-lg-2 d-flex flex-column pe-1 mb-2 mb-lg-0 justify-content-center">
							{renderChangeBadge(product)}
						</div>
						<div className="col-12 col-lg-2 d-flex pe-1 mb-2 mb-lg-0 align-items-center justify-content-end flex-wrap"></div>
					</div>
				</div>
			));
		}
		return null;
	};

	return (
		<>
			<div className="row g-0">
				<div className="col-auto mb-3 me-auto">
					<div className="w-auto ">
						<Link to="/warehouse" className="muted-link pb-1 d-inline-block breadcrumb-back">
							<img src={backIcon} />
							&nbsp;
							<span className="text-small align-middle text-muted">
								{type === 'inventory' ? 'Inwentaryzacje' : 'Korekty'}
							</span>
						</Link>
						<h1 className="mb-0 pb-0 d-flex flex-nowrap align-items-center" id="title">
							<img src={listekIcon} className="logo-leaf-heading mt-3 me-3" />
							{type === 'inventory' ? 'Magazyn' : 'Korekta ręczna'}
						</h1>
					</div>
				</div>
			</div>
			<div className="d-flex flex-wrap mb-0">
				<hr className="w-100" />
			</div>

			<div className="tab-content" id="pills-tabContent">
				<div className="tab-pane fade " id="stanmagazynowy" role="tabpanel" aria-labelledby="pills-home-tab">
					{/* <!-- patrz /stock.html, tutaj jest tylko szczegol inwentaryzacji --> */}
				</div>
				<div
					className="tab-pane fade show active"
					id="inwentaryzacja"
					role="tabpanel"
					aria-labelledby="inwentaryzacja-tab"
				>
					<div id="inwentaryzacja-list" className="d-flex flex-wrap gx-4 gy-0">
						<div
							id="inwentaryzacja-list-heading"
							className="g-0 mb-4 mb-lg-3 pe-4 d-flex col-12 align-content-center"
						>
							<div className="col-3 col-lg-3 ps-3">
								<div className="text-muted text-small">
									{type === 'inventory' ? 'INWENTARYZACJA' : 'KOREKTA RĘCZNA'}
								</div>
							</div>
							<div className="col-2 col-lg-3 ps-5">
								<div className="text-muted text-small">DATA</div>
							</div>
							<div className="col-3 col-lg-2">
								<div className="text-muted text-small">ILOŚĆ PRODUKTÓW</div>
							</div>
							<div className="col-3 col-lg-2">
								<div className="text-muted text-small">WYKONAWCA</div>
							</div>
							<div className="col-2 col-lg-2">
								{/* <div className="text-muted text-small">CZAS TRWANIA</div> */}
							</div>
						</div>

						<div className="card inwentaryzacja selected col-12 ps-0 mb-3 pe-4">
							<div className="row col-12 h-100 g-0 position-relative align-content-center">
								<Link
									to=""
									className="col-11 col-lg-3 d-flex flex-column mb-lg-0 mb-3 ps-3 pe-3 justify-content-center"
								>
									{history?.number}
								</Link>
								<div className="col-12 col-lg-3 d-flex flex-column pe-1 ps-5 py-3 mb-2 mb-lg-0 justify-content-center">
									<div>{moment(history?.createdAt).format('DD.MM.YYYY')}</div>
									<div className="text-small text-muted text-truncate position">
										{moment(history?.createdAt).format('HH:mm')}
									</div>
								</div>
								<div className="col-12 col-lg-2 d-flex flex-column pe-1 mb-2 mb-lg-0 justify-content-center">
									<div>{history?.products.length}</div>
								</div>
								<div className="col-12 col-lg-2 d-flex pe-1 mb-2 mb-lg-0 align-items-center flex-wrap">
									<Link
										to={
											loggedUser?.role === UserRoleTypes.ADMIN
												? `/users/${get(history, 'creator._id', '')}`
												: ''
										}
									>
										{get(history, 'creator.name', '-')}
									</Link>
								</div>
								<div className="col-12 col-lg-2 d-flex flex-column pe-1 mb-2 mb-lg-0 justify-content-center">
									{/* <div>1h 6min</div> */}
								</div>
							</div>
						</div>
						<div
							id="inwentaryzacja-products-heading"
							className="g-0 mb-4 mb-lg-3 pe-4 d-flex col-12 align-content-center"
						>
							<div className="col-3 col-lg-1 d-none d-lg-flex"> </div>
							<div className="col-3 col-lg-3 ps-3">
								<div className="text-muted text-small">NAZWA</div>
							</div>
							<div className="col-2 col-lg-2 ps-5">
								<div className="text-muted text-small">STAN PRZED</div>
							</div>
							<div className="col-2 col-lg-2">
								<div className="text-muted text-small">STAN PO</div>
							</div>
							<div className="col-3 col-lg-2">
								<div className="text-muted text-small">RÓŻNICA</div>
							</div>
							<div className="col-3 col-lg-2">
								<div className="text-muted text-small"></div>
							</div>
						</div>

						{renderList()}
					</div>
				</div>
			</div>
		</>
	);
};

export default WarehouseHistoryDetails;
