import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery, ListParams, parseListParams } from 'app/api';
import { BaseListResponse, Order } from '../app/types';

export const cockpitApi = createApi({
	baseQuery,
	reducerPath: 'cockpitApi',
	endpoints: builder => ({
		getLastOrders: builder.query<BaseListResponse<Order>, ListParams>({
			query: params => ({
				url: parseListParams('order/order', params),
				method: 'GET',
			}),
		}),
	}),
});

export const { useLazyGetLastOrdersQuery } = cockpitApi;
