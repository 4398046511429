import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery, ListParams, parseListParams, setFormData } from 'app/api';
import {
	BaseDocumentResponse,
	BaseListResponse,
	BaseSuccessResponse,
	CreateProductRequest,
	DeleteProductRequest,
	GetProductRequest,
	Product,
	UpdateProductRequest,
} from '../app/types';

export const productsApi = createApi({
	baseQuery,
	tagTypes: ['Product'],
	reducerPath: 'productsApi',
	endpoints: builder => ({
		createProduct: builder.mutation<BaseDocumentResponse<Product>, CreateProductRequest>({
			query: data => ({
				url: 'product/product',
				method: 'POST',
				body: setFormData(data),
			}),
			invalidatesTags: ['Product'],
		}),
		updateProduct: builder.mutation<BaseDocumentResponse<Product>, UpdateProductRequest>({
			query: ({ id, ...data }) => ({
				url: `product/product/${id}`,
				method: 'PUT',
				body: setFormData(data),
			}),
			invalidatesTags: ['Product'],
		}),
		getProducts: builder.query<BaseListResponse<Product>, ListParams>({
			query: params => ({
				url: parseListParams('product/product', params),
				method: 'GET',
			}),
			providesTags: ['Product'],
		}),
		getProduct: builder.query<BaseDocumentResponse<Product>, GetProductRequest>({
			query: ({ id }) => ({
				url: `product/product/${id}`,
				method: 'GET',
			}),
			providesTags: ['Product'],
		}),
		deleteProduct: builder.mutation<BaseSuccessResponse, DeleteProductRequest>({
			query: ({ id }) => ({
				url: `product/product/${id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['Product'],
		}),
	}),
});

export const {
	useCreateProductMutation,
	useUpdateProductMutation,
	useGetProductQuery,
	useLazyGetProductQuery,
	useGetProductsQuery,
	useLazyGetProductsQuery,
	useDeleteProductMutation,
} = productsApi;
