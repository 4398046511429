import { Outlet } from 'react-router-dom';

const LayoutComponent: React.FC = () => {
	return (
		<div id="content" className="p-4 p-md-5">
			<Outlet />
		</div>
	);
};

export default LayoutComponent;
