import { yupResolver } from '@hookform/resolvers/yup';
import { useAppDispatch } from 'app/hooks';
import adminSchema from 'app/schemas/admin.schema';
import { CreateAdminRequest, User, UserRoleTranslation, UserRoleTypes } from 'app/types';
import { handleApiErrors } from 'app/util';
import backIcon from 'assets/images/back.svg';
import listekIcon from 'assets/images/listek.svg';
import saveIcon from 'assets/images/save.svg';
import SelectComponent from 'components/Select.component';
import SwitchComponent from 'components/Switch.component';
import TextInputComponent from 'components/TextInput.component';
import { setIsLoading, setToast, ToastType } from 'features/appSlice';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useCreateAdminMutation, useLazyGetUserQuery, useUpdateAdminMutation } from 'services/users.service';

const AdminFormComponent: React.FC = () => {
	const { id } = useParams();
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const [user, setUser] = useState<User>();
	const [createAdmin] = useCreateAdminMutation();
	const [updateAdmin] = useUpdateAdminMutation();
	const [getUser] = useLazyGetUserQuery();
	const {
		register,
		handleSubmit,
		setError,
		setValue,
		formState: { errors, isSubmitted },
	} = useForm<CreateAdminRequest>({
		resolver: yupResolver(adminSchema),
		defaultValues: {
			active: true,
			edit: false,
		},
		reValidateMode: 'onChange',
	});

	useEffect(() => {
		const loadUser = async () => {
			try {
				if (id) {
					const res = await getUser({ id }).unwrap();

					if (res.success && res.document) {
						setUser(res.document);
						setValue('edit', true);
						if ('name' in res.document) {
							setValue('name', res.document.name);
						}
						if ('surname' in res.document) {
							setValue('surname', res.document.surname);
						}
						if ('active' in res.document) {
							setValue('active', res.document.active);
						}
						if ('role' in res.document) {
							setValue('role', res.document.role);
						}
						if ('email' in res.document) {
							setValue('email', res.document.email);
						}
					}
				}
			} catch (err) {
				dispatch(setToast({ type: ToastType.WARNING, text: 'Błąd wczytywania użytkownika' }));
			}
		};
		loadUser();
	}, [id]);

	const handleSave = async (data: CreateAdminRequest) => {
		try {
			dispatch(setIsLoading({ isLoading: true }));
			if (id) {
				if (isEmpty(data.password)) {
					delete data.password;
				}
				if (isEmpty(data.passwordConfirm)) {
					delete data.passwordConfirm;
				}
				await updateAdmin({ id, ...data }).unwrap();
			} else {
				await createAdmin({ ...data }).unwrap();
			}
			dispatch(setIsLoading({ isLoading: false }));
			navigate('/users');
		} catch (err) {
			dispatch(setIsLoading({ isLoading: false }));
			handleApiErrors(setError, err);
		}
	};

	return (
		<form onSubmit={handleSubmit(handleSave)}>
			<div className="row g-0">
				<div className="col-sm-6 mb-3 me-auto">
					<div className="w-auto">
						<Link to="/users" className="muted-link pb-1 d-inline-block breadcrumb-back">
							<img src={backIcon} />
							<span className="text-small text-muted align-middle">Użytkownicy</span>
						</Link>
						<h1 className="mb-0 pb-0 " id="title">
							<img src={listekIcon} className="logo-leaf-heading mt-3 me-3" />
							{id ? 'Edytuj' : 'Dodaj'} użytkownika
						</h1>
					</div>
				</div>
				<div className="col-12 col-sm-2 d-flex align-items-end justify-content-end mb-3 order-sm-3">
					<button
						type="submit"
						className="semigreen btn btn-outline-primary border-0 w-100 px-3"
						data-bs-toggle="tooltip"
						data-bs-placement="top"
						title="Zapisz"
						data-bs-original-title="Tooltip on top"
					>
						<img src={saveIcon} width="20" height="20" className="m-2" />
					</button>
				</div>
			</div>

			<div id="customer-details" className="row gx-4 gy-0">
				<div className="col-12">
					<h5 className="text-alternate mt-3 mb-2 ps-3">Dane</h5>
					<div className="card">
						<div className="card-body">
							<div className="row gx-4 gy-0">
								<div className="col-sm-12 col-md-3">
									<div className="input-group mb-3">
										<TextInputComponent
											label="Imię"
											type="text"
											register={register}
											id="name"
											isSubmited={isSubmitted}
											error={errors.name?.message}
											classes="w-100"
										/>
									</div>
								</div>
								<div className="col-sm-12 col-md-3">
									<div className="input-group mb-3">
										<TextInputComponent
											label="Nazwisko"
											type="text"
											register={register}
											id="surname"
											isSubmited={isSubmitted}
											error={errors.surname?.message}
											classes="w-100"
										/>
									</div>
								</div>
								<div className="col-sm-12 col-md-3">
									{id ? (
										<div className="input-group mb-3">
											<label className="form-label text-small ps-2 m-0 w-100">Rola</label>
											<input
												type="text"
												className="form-control w-100"
												disabled
												value={
													UserRoleTranslation[
														user?.role as keyof typeof UserRoleTranslation
													] || ''
												}
											/>
										</div>
									) : (
										<SelectComponent
											classes=""
											options={[
												{ value: UserRoleTypes.ADMIN, label: 'Administrator' },
												{ value: UserRoleTypes.OWNER, label: 'Właściciel sklepu' },
											]}
											register={register}
											id="role"
											label="Rola"
											error={errors.role?.message}
										/>
									)}
								</div>
								<div className="col-sm-12 col-md-3">
									<div className="input-group mb-3">
										<TextInputComponent
											label="Email"
											type="email"
											register={register}
											id="email"
											isSubmited={isSubmitted}
											error={errors.email?.message}
											classes="w-100"
										/>
									</div>
								</div>
							</div>
							<div className="row gx-4 gy-0">
								<div className="col-sm-12 col-md-3">
									<div className="input-group mb-3">
										<TextInputComponent
											label="Hasło"
											type="password"
											register={register}
											id="password"
											isSubmited={isSubmitted}
											error={errors.password?.message}
											classes="w-100"
										/>
									</div>
								</div>
								<div className="col-sm-12 col-md-3">
									<div className="input-group mb-3">
										<TextInputComponent
											label="Powtórz hasło"
											type="password"
											register={register}
											id="passwordConfirm"
											isSubmited={isSubmitted}
											error={errors.passwordConfirm?.message}
											classes="w-100"
										/>
									</div>
								</div>
								{id && (
									<div className="col-sm-12 col-md-3">
										<label className="form-label text-small ps-2">Ustawienia</label>
										<SwitchComponent
											classes="form-check form-switch"
											register={register}
											id="active"
											label="Aktywny"
										/>
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</form>
	);
};

export default AdminFormComponent;
