import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { User } from '../app/types';

type AuthState = {
	user: User | null;
	accessToken?: string | null;
};

const initialState: AuthState = {
	user: null,
	accessToken: null,
};

const slice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		setCredentials: (state, { payload: { user, accessToken } }: PayloadAction<AuthState>) => {
			if (accessToken) {
				setAccessToken(accessToken);
				state.user = user;
				state.accessToken = accessToken;
			}
		},
		removeCredentials: state => {
			removeAccessToken();
			state.user = null;
			state.accessToken = null;
		},
	},
});

const setAccessToken = (token: string): void => {
	if (token) {
		localStorage.setItem('accessToken', token);
	}
};

const removeAccessToken = (): void => {
	localStorage.removeItem('accessToken');
};

export const { setCredentials, removeCredentials } = slice.actions;

export default slice.reducer;
