const LogoWhiteIcon = () => (
	<svg version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 116 280.2">
		<g>
			<path
				fill="#FFFFFF"
				d="M33.3,230.2h8v49.2h-8v-4.1c-1.1,1.5-2.4,2.7-4,3.6c-1.7,0.9-3.6,1.4-5.5,1.4c-2.3,0-4.6-0.4-6.7-1.3
			c-2-0.9-3.9-2.1-5.5-3.7c-1.6-1.6-2.8-3.4-3.7-5.5c-1.8-4.3-1.8-9.1,0-13.4c0.9-2.1,2.1-3.9,3.7-5.5c1.6-1.6,3.4-2.8,5.5-3.7
			c2.1-0.9,4.4-1.4,6.7-1.3c1.9,0,3.8,0.4,5.5,1.4c1.6,0.9,3,2.1,4,3.6V230.2z M23.9,272.5c1.6,0,3.2-0.4,4.5-1.3
			c1.3-0.9,2.4-2.1,3.1-3.5c1.5-3,1.5-6.6,0-9.6c-0.7-1.4-1.8-2.6-3.1-3.5c-1.3-0.9-2.9-1.3-4.5-1.3c-1.6,0-3.2,0.4-4.6,1.3
			c-1.4,0.9-2.5,2-3.3,3.5c-1.7,3-1.7,6.6,0,9.6c0.8,1.4,1.9,2.6,3.3,3.5C20.7,272.1,22.3,272.5,23.9,272.5L23.9,272.5z"
			/>
			<path
				fill="#FFFFFF"
				d="M63.4,280.2c-2.9,0-5.8-0.8-8.2-2.3c-2.5-1.5-4.5-3.7-5.9-6.3c-3.8-6.8-2.7-15.2,2.6-20.9
			c1.5-1.6,3.3-2.8,5.2-3.7c4.3-1.9,9.2-1.8,13.4,0.3c2.1,1.1,4,2.6,5.4,4.4c3.1,4,4.5,9,3.7,14h-24c0.3,1.2,0.8,2.3,1.5,3.3
			c1.4,2,3.8,3.2,6.3,3.2c1.3,0,2.7-0.3,3.8-1c1.2-0.7,2.1-1.7,2.9-2.8l8.2,1.9c-1.3,2.9-3.4,5.3-6,7.1
			C69.6,279.3,66.5,280.3,63.4,280.2z M55.3,259.7h16.1c-0.2-1.3-0.8-2.5-1.5-3.6c-0.8-1-1.7-1.9-2.9-2.5c-2.3-1.3-5-1.3-7.3,0
			c-1.1,0.6-2.1,1.5-2.8,2.5C56.1,257.2,55.6,258.4,55.3,259.7z"
			/>
			<path fill="#FFFFFF" d="M84.9,279.4v-49.2h8v49.2H84.9z" />
			<path
				fill="#FFFFFF"
				d="M105,241.7c-1.1,0-2.2-0.4-3-1.2c-0.8-0.8-1.2-1.8-1.2-2.9c0-1.1,0.4-2.2,1.2-2.9c1.6-1.6,4.2-1.6,5.9,0
			c0.8,0.8,1.2,1.8,1.2,2.9c0,1.1-0.4,2.2-1.2,2.9C107.2,241.3,106.1,241.7,105,241.7z M101,246.6h8v32.8h-8V246.6z"
			/>
			<path
				fill="#FFFFFF"
				fillOpacity="0.5"
				d="M57.5,85.1c-9.5,0-16.7,2.7-21.7,8.1c-5,5.4-7.5,13-7.5,22.8v64.5c0,9.8,2.5,17.4,7.5,22.8
			c5,5.4,12.3,8.1,21.7,8.1c9.5,0,16.7-2.7,21.7-8.1c5-5.4,7.5-13,7.5-22.8v-64.5c0-9.8-2.5-17.4-7.5-22.8
			C74.2,87.8,66.9,85.1,57.5,85.1"
			/>
			<path
				fill="#FFFFFF"
				d="M7.4,203.3C2.4,198,0,190.3,0,180.4v-64.5C0,106,2.5,98.4,7.4,93c4.9-5.3,12-8,21.4-8c9.3,0,16.5,2.7,21.4,8
			c4.9,5.3,7.4,13,7.4,22.9v10.5H39.3v-11.7c0-8.1-3.3-12.1-10-12.1c-6.7,0-10,4-10,12.1v67.1c0,8,3.3,11.9,10,11.9s10-4,10-11.9v-24
			h-11v-17.5h29.3v40.1c0,9.9-2.5,17.6-7.4,22.9s-12,8-21.4,8C19.4,211.3,12.3,208.6,7.4,203.3z"
			/>
			<path
				fill="#FFFFFF"
				d="M111.1,3.1c-28.8-10-60.2,5.2-70.2,34c-4.1,11.7-4.1,24.5,0,36.2c28.8,10,60.2-5.2,70.2-34
			C115.2,27.5,115.2,14.8,111.1,3.1z"
			/>
			<path
				fill="#FFFFFF"
				fillOpacity="0.25"
				d="M108.5,93.1c-5-5.4-12.3-8.1-21.7-8.1c-5.7,0-10.6,1-14.6,2.9c2.7,1.3,5.1,3,7.1,5.2c5,5.4,7.5,13,7.5,22.8
			v64.5c0,9.8-2.5,17.4-7.5,22.8c-2,2.2-4.4,3.9-7.1,5.2c4.1,1.9,8.9,2.9,14.6,2.9c9.5,0,16.7-2.7,21.7-8.1c5-5.4,7.5-13,7.5-22.8
			v-64.5C116,106.1,113.5,98.5,108.5,93.1z"
			/>
		</g>
	</svg>
);

export default LogoWhiteIcon;
