import { useAppSelector } from 'app/hooks';
import backIcon from 'assets/images/back.svg';
import listekIcon from 'assets/images/listek.svg';
import logoIcon from 'assets/images/logo.svg';
import plusIcon from 'assets/images/plus.svg';
import searchIcon from 'assets/images/search.svg';
import PaginationComponent from 'components/Pagination.component';
import { get, isArray, isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { useGetProductsQuery } from 'services/products.service';
import ProductCard from './ProductCard.component';

const ProductsComponent: React.FC = () => {
	const navigate = useNavigate();
	const selectedShop = useAppSelector(state => state.app.selectedShop);
	const [currentPage, setCurrentPage] = useState(1);
	const [query, setQuery] = useState('');
	const [limit, setLimit] = useState(10);
	const { data, refetch } = useGetProductsQuery({
		shopId: selectedShop,
		limit,
		...(query.length > 2 ? { name: query } : {}),
		offset: (currentPage - 1) * limit,
		'sort[name]': 1,
	});

	useEffect(() => {
		setCurrentPage(1);
		refetch();
	}, [query, limit]);

	useEffect(() => {
		refetch();
	}, [currentPage]);

	const renderList = () => {
		if (isArray(data?.documents) && !isEmpty(data?.documents)) {
			return data?.documents.map(product => <ProductCard product={product} key={product._id} />);
		}
		return (
			<div className="empty-list col-sm-12 p-5 justify-content-center d-flex flex-wrap">
				<img src={logoIcon} />
				<h4 className="text-muted w-100 text-center mt-4">Brak produktów do wyświetlenia.</h4>
			</div>
		);
	};

	return (
		<>
			<div className="row g-0">
				<div className="col-8 mb-3 me-auto">
					<div className="w-auto ">
						<Link to="/cockpit" className="muted-link pb-1 d-inline-block breadcrumb-back">
							<img src={backIcon} />
							&nbsp;
							<span className="text-small align-middle">Kokpit</span>
						</Link>
						<h1 className="mb-0 pb-0 d-flex flex-nowrap align-items-center" id="title">
							<img src={listekIcon} className="logo-leaf-heading mt-3 me-3" />
							Lista produktów
						</h1>
					</div>
				</div>
				<div className="col-12 col-sm-6 col-md-auto d-flex align-items-end justify-content-end mb-3 order-sm-3">
					<button
						type="button"
						className="semigreen border-0 btn btn-outline-primary w-100 px-3"
						onClick={() => navigate('/product')}
					>
						<img src={plusIcon} />
						&nbsp;
						<span>Dodaj produkt</span>
					</button>
				</div>
			</div>

			<div className="row mb-2">
				<div className="col-sm-12 col-md-5 col-lg-3 col-xxl-2 mb-1">
					<div className="d-inline-block float-md-start me-1 mb-1 search-input-container w-100 shadow bg-white">
						<input
							className="form-control"
							placeholder="Szukaj"
							value={query}
							onChange={e => setQuery(e.target.value)}
						/>
						<span className="search-magnifier-icon">
							<img src={searchIcon} />
						</span>
					</div>
				</div>
				<div className="col-sm-12 col-md-7 col-lg-9 col-xxl-10 text-end d-flex justify-content-end mb-1">
					<select
						className="form-select shadow justify-self-end pe-5"
						aria-label="Pokaż"
						value={limit}
						onChange={e => setLimit(parseInt(e.target.value))}
					>
						<option value="10">10 produktów</option>
						<option value="25">25 produktów</option>
						<option value="50">50 produktów</option>
					</select>
				</div>
			</div>

			<div id="product-list" className="d-flex flex-wrap gx-4 gy-0">
				{get(data?.documents, 'total', 0) > 0 && (
					<div id="product-list-heading" className="g-0 mb-2 mb-lg-2 pe-4 d-flex col-12 align-content-center">
						<div className="col-3 col-lg-1 d-none d-lg-flex"> </div>
						<div className="col-3 col-lg-3 ps-3">
							<div className="text-muted text-small">NAZWA</div>
						</div>
						<div className="col-2 col-lg-4">
							<div className="text-muted text-small">OPIS</div>
						</div>
						<div className="col-2 col-lg-1">
							<div className="text-muted text-small">CENA NETTO</div>
						</div>
						<div className="col-3 col-lg-1">
							<div className="text-muted text-small">STATUS</div>
						</div>
						<div className="col-3 col-lg-2">
							<div className="text-muted text-small"></div>
						</div>
					</div>
				)}

				{renderList()}

				<div className="w-100 d-flex mt-2 justify-content-center">
					<PaginationComponent
						currentPage={currentPage}
						totalCount={data?.total || 0}
						pageSize={limit}
						onPageChange={setCurrentPage}
						siblingCount={2}
					/>
				</div>
			</div>
		</>
	);
};

export default ProductsComponent;
