import { yupResolver } from '@hookform/resolvers/yup';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import productSchema from 'app/schemas/product.schema';
import { CreateProductRequest } from 'app/types';
import { getConfirmation, handleApiErrors, translateError } from 'app/util';
import previewTemplate from 'assets/dropzone/templates';
import backIcon from 'assets/images/back.svg';
import printIcon from 'assets/images/print.svg';
import deleteIcon from 'assets/images/delete.svg';
import listekIcon from 'assets/images/listek.svg';
import plusIcon from 'assets/images/plus.svg';
import saveIcon from 'assets/images/save.svg';
import SelectComponent from 'components/Select.component';
import SwitchComponent from 'components/Switch.component';
import TextareaComponent from 'components/Textarea.component';
import TextInputComponent from 'components/TextInput.component';
import Dropzone from 'dropzone';
import { setIsLoading as setGlobalLoader } from 'features/appSlice';
import { get, isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { DropzoneComponent } from 'react-dropzone-component';
import { useForm } from 'react-hook-form';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
	useCreateProductMutation,
	useDeleteProductMutation,
	useLazyGetProductQuery,
	useUpdateProductMutation,
} from 'services/products.service';

const ProductForm: React.FC = () => {
	const { id } = useParams();
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const selectedShop = useAppSelector(state => state.app.selectedShop);
	// const [isLoading, setIsLoading] = useState(false);
	const [createProduct] = useCreateProductMutation();
	const [updateProduct] = useUpdateProductMutation();
	const [deleteProduct] = useDeleteProductMutation();
	const [getProduct, { isLoading: isProductLoading }] = useLazyGetProductQuery();
	const [eanText, setEanText] = useState('');

	const {
		register,
		handleSubmit,
		setValue,
		getValues,
		setError,
		watch,
		formState: { errors, isSubmitted },
	} = useForm<CreateProductRequest>({
		resolver: yupResolver(productSchema),
		defaultValues: {
			ingredients: [''],
			media: [],
			active: true,
			shopId: selectedShop,
			unitPriceNetto: 0,
			photos: [],
		},
		reValidateMode: 'onChange',
	});

	useEffect(() => {
		setValue('shopId', selectedShop);
	}, [selectedShop]);

	useEffect(() => {
		dispatch(setGlobalLoader({ isLoading: isProductLoading }));
	}, [isProductLoading]);

	useEffect(() => {
		const loadProduct = async () => {
			try {
				if (id) {
					// setIsLoading(true);
					const res = await getProduct({ id }).unwrap();
					if (res.success && res.document) {
						if ('name' in res.document) {
							setValue('name', res.document.name);
						}
						if ('shopId' in res.document) {
							setValue('shopId', res.document.shopId);
						}
						if ('shortDescription' in res.document) {
							setValue('shortDescription', res.document.shortDescription);
						}
						if ('description' in res.document) {
							setValue('description', res.document.description);
						}
						if ('capacity' in res.document) {
							setValue('capacity', res.document.capacity);
						}
						if ('unitPriceNetto' in res.document) {
							setValue('unitPriceNetto', res.document.unitPriceNetto);
						}
						if ('tax' in res.document) {
							setValue('tax', res.document.tax);
						}
						if ('adultOnly' in res.document) {
							setValue('adultOnly', res.document.adultOnly);
						}
						if ('active' in res.document) {
							setValue('active', res.document.active);
						}
						if ('ean' in res.document) {
							setEanText(res.document.ean);
							setValue('ean', res.document.ean);
						}
						if ('ingredients' in res.document) {
							setValue('ingredients', res.document.ingredients);
						}
						if ('media' in res.document) {
							setValue('media', res.document.media);
						}
					}
					// setIsLoading(false);
				}
			} catch (err) {
				// setIsLoading(false);
			}
		};
		loadProduct();
	}, [id]);

	const handleSave = async (data: CreateProductRequest) => {
		try {
			dispatch(setGlobalLoader({ isLoading: true }));
			// setIsLoading(true);
			if (id) {
				await updateProduct({ id, ...data }).unwrap();
			} else {
				await createProduct({ ...data }).unwrap();
			}
			// setIsLoading(false);
			dispatch(setGlobalLoader({ isLoading: false }));
			navigate('/products');
		} catch (err) {
			dispatch(setGlobalLoader({ isLoading: false }));
			handleApiErrors(setError, err);
			// setIsLoading(false);
		}
	};

	const handleDelete = async () => {
		try {
			// setIsLoading(true);
			const confirmed = await getConfirmation('Czy chcesz usunąć produkt?');
			if (confirmed && id) {
				dispatch(setGlobalLoader({ isLoading: true }));
				const res = await deleteProduct({ id }).unwrap();
				dispatch(setGlobalLoader({ isLoading: false }));
				// setIsLoading(false);
				if (res.success) {
					navigate('/products');
				} else {
					//Toast Error
				}
			}
		} catch (err) {
			dispatch(setGlobalLoader({ isLoading: false }));
			// setIsLoading(false);
			//Toast Error
		}
	};

	const handleAddIngredient = () => {
		setValue('ingredients', [...getValues('ingredients'), '']);
	};

	const handleDeleteIngredient = (index: number) => {
		const newIngredients = getValues('ingredients');
		newIngredients.splice(index, 1);
		setValue('ingredients', newIngredients);
	};

	const handleFileAdd = (file: Dropzone.DropzoneFile) => {
		setValue('photos', [...getValues('photos'), new File([file], file.name)]);
	};

	const handleFileDelete = (file: Dropzone.DropzoneFile) => {
		const newFiles = getValues('photos');
		const fileIndex = newFiles.findIndex((photo: Dropzone.DropzoneFile) => photo.name === file.name);
		if (fileIndex > -1) {
			newFiles.splice(fileIndex, 1);
			setValue('photos', newFiles);
		}
	};

	const media = watch('media');
	const ingredients = watch('ingredients');

	const handleDeleteMedia = (index: number) => {
		const newMedia = getValues('media');
		newMedia.splice(index, 1);
		setValue('media', newMedia);
	};

	const renderCurrentMedia = () => {
		if (!isEmpty(media)) {
			return media.map((url, index) => (
				<div className="dz-preview col-6 border-0 h-auto me-0 dz-image-preview" key={url + index}>
					<div className="d-flex flex-column border rounded-md">
						<div className="p-0 position-relative image-container w-100">
							<div className="preview-container rounded-0 rounded-md-top">
								<img
									className="img-thumbnail border-0 rounded-0 rounded-md-top sh-18"
									src={url}
									alt={`Zdjęcie ${index + 1}`}
								/>
							</div>
						</div>
						<div className="ps-3 pt-3 pe-2 pb-1 dz-details position-relative w-100">
							<div>
								<span>{`Zdjęcie ${index + 1}`}</span>
							</div>
							<div className="text-primary text-extra-small">
								<strong>&nbsp;</strong>
							</div>
						</div>
						<a onClick={() => handleDeleteMedia(index)} className="remove">
							<img src={deleteIcon} />
						</a>
					</div>
				</div>
			));
		}
		return null;
	};

	const handlePrintBarcode = () => {
		const mywindow = window.open('');
		if (mywindow) {
			mywindow.document.write(
				`<img src='http://bwipjs-api.metafloor.com/?bcid=ean13&text=${eanText}&includetext' alt='kod kreskowy'/>'`,
			);
			mywindow.document.close();
			mywindow.focus();
			mywindow.onload = function () {
				mywindow.print();
			};
			setTimeout(() => {
				mywindow.close();
			}, 500);
		}
		return true;
	};

	return (
		<form onSubmit={handleSubmit(handleSave)}>
			<div className="row g-0">
				<div className="col-sm-6 mb-3 me-auto">
					<div className="w-auto">
						<Link to="/products" className="muted-link pb-1 d-inline-block breadcrumb-back">
							<img src={backIcon} />
							&nbsp;
							<span className="text-small text-muted align-middle">Produkty</span>
						</Link>
						<h1 className="mb-0 pb-0 " id="title">
							<img src={listekIcon} className="logo-leaf-heading mt-3 me-3" />
							{id ? 'Edytuj produkt' : 'Nowy produkt'}
						</h1>
					</div>
				</div>
				<div className="col-12 col-sm-2 d-flex align-items-end justify-content-end mb-3 order-sm-3">
					<button
						type="submit"
						className="semigreen btn btn-outline-primary border-0 w-100 px-3"
						data-bs-toggle="tooltip"
						data-bs-placement="top"
						title="Zapisz zmiany"
						data-bs-original-title="Tooltip on top"
					>
						<img src={saveIcon} width="20" height="20" className="m-2" />
					</button>
					{id && (
						<>
							<button
								type="button"
								className="semigreen btn btn-outline-primary border-0 w-100 px-3 ms-3"
								data-bs-toggle="tooltip"
								data-bs-placement="top"
								title="Drukuj kod"
								data-bs-original-title="Tooltip on top"
								onClick={handlePrintBarcode}
							>
								<img src={printIcon} className="m-2" />
							</button>
							<button
								type="button"
								className="semired btn btn-outline-danger border-0 w-100 px-3 ms-3"
								data-bs-toggle="tooltip"
								data-bs-placement="top"
								title="Usuń produkt"
								data-bs-original-title="Tooltip on top"
								onClick={handleDelete}
							>
								<img src={deleteIcon} className="m-2" />
							</button>
						</>
					)}
				</div>
			</div>

			<div id="product-details" className="row gx-4 gy-0">
				<div className="col-sm-8 col-md-8 col-sm-12">
					<h5 className="text-alternate mt-3 mb-2 ps-3">Dane produktu</h5>
					<div className="card">
						<div className="card-body">
							<div className="row gx-4 gy-0">
								<div className="col-sm-12 col-md-6">
									<TextInputComponent
										label="Nazwa"
										type="text"
										register={register}
										id="name"
										isSubmited={isSubmitted}
										error={errors.name?.message}
										classes="input-group mb-3"
									/>
								</div>
								<div className="col-sm-12 col-md-3">
									<TextInputComponent
										label="Gramatura"
										type="text"
										register={register}
										id="capacity"
										isSubmited={isSubmitted}
										error={errors.capacity?.message}
										classes="input-group mb-3"
									/>
								</div>
								<div className="col-sm-12 col-md-3">
									<TextInputComponent
										label="Kod EAN"
										type="text"
										register={register}
										id="ean"
										isSubmited={isSubmitted}
										error={errors.ean?.message}
										classes="input-group mb-3"
									/>
								</div>
							</div>
							<div className="row gx-4 gy-0">
								<div className="col-sm-12 col-md-6">
									<TextareaComponent
										label="Opis skrócony"
										placeholder="Dodaj opis produktu"
										register={register}
										id="shortDescription"
										isSubmited={isSubmitted}
										error={errors.shortDescription?.message}
										classes="input-group mb-3"
										labelClasses='"form-label text-small ps-2"'
									/>
								</div>
								<div className="col-sm-12 col-md-6">
									<label className="form-label text-small ps-2">Ustawienia</label>
									<SwitchComponent
										classes="form-check form-switch"
										register={register}
										id="active"
										label="Produkt aktywny"
									/>
									<SwitchComponent
										classes="form-check form-switch mt-1"
										register={register}
										id="adultOnly"
										label="Dla pełnoletnich"
									/>
								</div>
								<div className="col-12">
									<TextareaComponent
										label="Pełny opis"
										placeholder="Dodaj pełny opis produktu"
										register={register}
										id="description"
										isSubmited={isSubmitted}
										error={errors.description?.message}
										classes="col-sm-12 col-md-12"
										labelClasses='"form-label text-small ps-2"'
									/>
								</div>
							</div>
						</div>
					</div>
					<h5 className="text-alternate mt-3 mb-2 ps-3">Skład</h5>
					<div className="card ingredients-table">
						<div className="card-body">
							<div className="row gx-4 gy-0">
								<div className="col-sm-12 col-md-6">
									<div className="input-group">
										<label className="form-label text-small ps-2">Nazwa</label>
									</div>
								</div>
							</div>
							{ingredients.map((ingredient, idx) => (
								<div key={`${ingredient}${idx}`}>
									<div className="row gx-4 gy-0">
										<div className="col-12 col-md-11">
											<div className="input-group d-flex flex-wrap">
												<TextInputComponent
													label="&nbsp;"
													type="text"
													register={register}
													id={`ingredients[${idx}]`}
													isSubmited={isSubmitted}
													error={get(errors, `ingredients[${idx}].message`)}
													classes=""
												/>
											</div>
										</div>
										<div className="col-sm-12 col-md-1 d-flex align-items-end">
											<div className="delete-ingredient input-group mt-3">
												<button
													onClick={() => handleDeleteIngredient(idx)}
													type="button"
													className="btn btn-outline-danger px-3 border-0"
													data-bs-toggle="tooltip"
													data-bs-placement="top"
													title="Usuń składnik"
													data-bs-original-title="Tooltip on top"
												>
													<img src={deleteIcon} />
												</button>
											</div>
										</div>
									</div>
								</div>
							))}
						</div>

						<button
							type="button"
							className="btn btn-outline-primary w-100 border-0 px-3"
							onClick={() => handleAddIngredient()}
						>
							<img src={plusIcon} />
							<span>Dodaj składnik</span>
						</button>
					</div>
				</div>
				<div className="col-md-4 col-sm-12 ">
					{id && eanText && (
						<>
							<h5 className="text-alternate mt-3 mb-2 ps-3">Kod kreskowy</h5>
							<div className="card">
								<div className="card-body text-center">
									<img
										alt="Kod kreskowy"
										src={`http://bwipjs-api.metafloor.com/?bcid=ean13&text=${eanText}&includetext`}
									/>
								</div>
								{/* <div className="card-footer text-center">
									<button className="btn btn-secondary" onClick={handlePrintBarcode}>
										Drukuj
									</button>
								</div> */}
							</div>
						</>
					)}

					<h5 className="text-alternate mt-3 mb-2 ps-3">Cena</h5>
					<div className="card">
						<div className="card-body">
							<TextInputComponent
								label="Cena netto (zł)"
								type="number"
								register={register}
								id="unitPriceNetto"
								isSubmited={isSubmitted}
								error={errors.unitPriceNetto?.message}
								classes="input-group mb-3"
							/>
							<div className="col-12">
								<SelectComponent
									classes="mb-0"
									options={[
										{ value: '23', label: '23%' },
										{ value: '8', label: '8%' },
										{ value: '5', label: '5%' },
										{ value: '0', label: '0%' },
									]}
									register={register}
									id="tax"
									label="Podatek VAT"
									error={errors.tax?.message}
								/>
							</div>
						</div>
					</div>
					<h5 className="text-alternate mt-3 mb-2 ps-3">Galeria</h5>
					<div className="card">
						<div className="card-body">
							<DropzoneComponent
								eventHandlers={{
									addedfile: handleFileAdd,
									removedfile: handleFileDelete,
								}}
								djsConfig={{
									uploadMultiple: false,
									acceptedFiles: 'image/*',
									maxFilesize: 10, //10mb
									thumbnailWidth: 350,
									thumbnailHeight: 200,
									thumbnailMethod: 'contain',
									autoProcessQueue: false,
									previewTemplate: previewTemplate,
									dictDefaultMessage: 'Przeciągnij plik lub kliknij tutaj',
									dictFileTooBig: 'Zbyt duży rozmiar pliku',
									init: function () {
										this.on('error', (file: Dropzone.DropzoneFile) => {
											this.removeFile(file);
										});
									},
								}}
								config={{
									postUrl: 'no-url',
									dropzoneSelector: 'div#dropzoneProductGallery',
								}}
							/>
							<div className="dropzone-columns">
								<div className="dropzone dropzone-columns dz-started" id="dropzoneProductGallery">
									{renderCurrentMedia()}
								</div>
							</div>
							{errors.media && (
								<p className="text-danger text-center">
									{translateError(get(errors, 'media.message'))}
								</p>
							)}
						</div>
					</div>
				</div>
			</div>
		</form>
	);
};

export default ProductForm;
