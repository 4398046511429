import React, { useState, useEffect } from 'react';

interface TextInputComponentProps {
	id: string;
	placeholder?: string;
	label?: string;
	type: string;
	error?: string | undefined;
	register: any;
	classes: string;
	isSubmited?: boolean;
	disabled?: boolean;
}

const TextInputComponent: React.FC<TextInputComponentProps> = ({
	id,
	placeholder,
	label,
	type,
	error,
	register,
	classes,
	isSubmited,
	disabled,
}) => {
	const [hideError, setHideError] = useState(false);
	const [inputType, setInputType] = useState('text');
	const inputWrapperClasses = `${classes} input-group`;
	const inputClasses = `form-control ${error ? 'is-invalid' : isSubmited && !error ? 'is-valid' : ''}`;

	useEffect(() => {
		setInputType(type);
	}, [type]);

	const handleFocus = () => {
		setHideError(true);
		document.getElementById(id)?.focus();
	};

	return (
		<div className={inputWrapperClasses}>
			{label && <label className="form-label text-small ps-2 m-0 w-100">{label}</label>}
			<input
				{...register(id)}
				type={inputType}
				className={inputClasses}
				placeholder={placeholder}
				autoComplete="new-password"
				onFocus={() => handleFocus()}
				onBlur={() => setHideError(false)}
				disabled={disabled}
			/>
			{error && !hideError && (
				<div className="invalid-tooltip" onClick={() => setHideError(true)}>
					{error}
				</div>
			)}
		</div>
	);
};

export default TextInputComponent;
