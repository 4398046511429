import { useAppSelector } from 'app/hooks';
import logoIcon from 'assets/images/logo.svg';
import searchIcon from 'assets/images/search.svg';
import PaginationComponent from 'components/Pagination.component';
import { get, isArray, isEmpty } from 'lodash';
import TransactionCard from 'pages/transaction/TransactionCard.component';
import { useEffect, useState } from 'react';
import { useGetOrdersQuery } from 'services/orders.service';

const WarehouseTransactions: React.FC = () => {
	const selectedShop = useAppSelector(state => state.app.selectedShop);
	const [limit, setLimit] = useState(10);
	const [query, setQuery] = useState('');
	const [currentPage, setCurrentPage] = useState(1);

	const { data, refetch } = useGetOrdersQuery({
		shopId: selectedShop,
		limit,
		...(query.length > 2 ? { number: query } : {}),
		offset: (currentPage - 1) * limit,
	});

	useEffect(() => {
		setCurrentPage(1);
		refetch();
	}, [query, limit]);

	useEffect(() => {
		refetch();
	}, [currentPage]);

	const renderList = () => {
		if (isArray(data?.documents) && !isEmpty(data?.documents)) {
			return data?.documents.map(order => <TransactionCard transaction={order} key={order._id} />);
		}
		return (
			<div className="empty-list col-sm-12 p-5 justify-content-center d-flex flex-wrap">
				<img src={logoIcon} />
				<h4 className="text-muted w-100 text-center mt-4">Brak transakcji do wyświetlenia.</h4>
			</div>
		);
	};

	return (
		<div className="tab-pane fade" id="historia" role="tabpanel" aria-labelledby="historia-tab">
			<div className="row mb-2">
				<div className="col-sm-12 col-md-5 col-lg-3 col-xxl-2 mb-1">
					<div className="d-inline-block float-md-start me-1 mb-1 search-input-container w-100 shadow bg-white">
						<input
							className="form-control"
							placeholder="Szukaj"
							value={query}
							onChange={e => setQuery(e.target.value)}
						/>
						<span className="search-magnifier-icon">
							<img src={searchIcon} />
						</span>
					</div>
				</div>
				<div className="col-sm-12 col-md-7 col-lg-9 col-xxl-10 text-end d-flex justify-content-end mb-1">
					<select
						className="form-select shadow justify-self-end pe-5"
						aria-label="Pokaż"
						onChange={(e: any) => setLimit(e.target.value)}
					>
						<option value="10">10 pozycji</option>
						<option value="25">25 pozycji</option>
						<option value="50">50 pozycji</option>
					</select>
				</div>
			</div>

			<div id="product-list" className="d-flex flex-wrap gx-4 gy-0">
				{get(data, 'total', 0) > 0 && (
					<div
						id="transaction-list-heading"
						className="g-0 mb-2 mb-lg-2 pe-4 d-flex col-12 align-content-center"
					>
						<div className="col-3 col-lg-2 ps-3">
							<div className="text-muted text-small">SPRZEDAŻ</div>
						</div>
						<div className="col-2 col-lg-2 ps-5">
							<div className="text-muted text-small">DATA</div>
						</div>
						<div className="col-2 col-lg-1">
							<div className="text-muted text-small">CZAS TRWANIA</div>
						</div>
						<div className="col-3 col-lg-1">
							<div className="text-muted text-small">PRODUKTY</div>
						</div>
						<div className="col-3 col-lg-2">
							<div className="text-muted text-small">SUMA</div>
						</div>
						<div className="col-3 col-lg-2">
							<div className="text-muted text-small">WYKONAWCA</div>
						</div>
						<div className="col-3 col-lg-2">
							<div className="text-muted text-small">STATUS</div>
						</div>
					</div>
				)}
				{renderList()}
			</div>

			<div className="w-100 d-flex mt-2 justify-content-center">
				<PaginationComponent
					currentPage={currentPage}
					totalCount={data?.total || 0}
					pageSize={limit}
					onPageChange={setCurrentPage}
					siblingCount={2}
				/>
			</div>
		</div>
	);
};

export default WarehouseTransactions;
