const CockpitIcon = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="18"
		height="18"
		viewBox="0 0 20 20"
		fill="#b7d13c"
		stroke="#fff"
		strokeWidth="1.5"
		strokeLinecap="round"
		strokeLinejoin="round"
	>
		<path d="M4 7V15.3215C4 15.9396 4 16.2487 4.06752 16.5019C4.25329 17.1986 4.79968 17.7414 5.4976 17.9225C5.75126 17.9883 6.06033 17.9863 6.67848 17.9822V17.9822C6.98387 17.9801 7.13657 17.9791 7.26195 17.9451C7.6068 17.8515 7.8755 17.581 7.96681 17.2355C8 17.1099 8 16.9572 8 16.6518V14.2955C8 13.5932 8 13.2421 8.16853 12.9899C8.24149 12.8807 8.33524 12.7869 8.44443 12.714C8.69665 12.5455 9.04777 12.5455 9.75 12.5455H10.25C10.9522 12.5455 11.3033 12.5455 11.5556 12.714C11.6648 12.7869 11.7585 12.8807 11.8315 12.9899C12 13.2421 12 13.5932 12 14.2955V16.6518C12 16.9572 12 17.1099 12.0332 17.2355C12.1245 17.581 12.3932 17.8515 12.7381 17.9451C12.8634 17.9791 13.0161 17.9801 13.3215 17.9822V17.9822C13.9397 17.9863 14.2487 17.9883 14.5024 17.9225C15.2003 17.7414 15.7467 17.1986 15.9325 16.5019C16 16.2487 16 15.9396 16 15.3215V7" />
		<path d="M2.11593 6.17395L2.86073 3.62035C2.95247 3.30582 3.11178 3.01511 3.32753 2.76853C3.75488 2.28014 4.37224 2 5.0212 2H14.9788C15.6278 2 16.2451 2.28014 16.6725 2.76853C16.8882 3.01511 17.0475 3.30582 17.1393 3.62035L17.8841 6.17396C18.2142 7.30593 16.8693 8.17367 15.974 7.40629C15.4489 6.95621 14.6516 7.04917 14.2442 7.60798L13.75 8.28571C13.1321 9.13312 11.8679 9.13312 11.25 8.28571C10.6321 7.43831 9.3679 7.43831 8.75 8.28571C8.1321 9.13312 6.8679 9.13312 6.25 8.28571L5.75582 7.60798C5.34835 7.04917 4.55108 6.95621 4.02599 7.40629C3.13072 8.17367 1.78577 7.30593 2.11593 6.17395Z" />
	</svg>
);

export default CockpitIcon;
