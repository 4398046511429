import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery, ListParams, parseListParams } from 'app/api';
import {
	BaseDocumentResponse,
	BaseListResponse,
	ChangeStockRequest,
	GetStockProduct,
	GetStockRequest,
	GetWarehouseHistoryRequest,
	GetWarehouseRequest,
	Warehouse,
	WarehouseHistory,
} from '../app/types';

export const warehousesApi = createApi({
	baseQuery,
	tagTypes: ['Warehouse', 'WarehouseHistory'],
	reducerPath: 'warehousesApi',
	endpoints: builder => ({
		changeStock: builder.mutation<BaseDocumentResponse<Warehouse>, ChangeStockRequest>({
			query: ({ id, ...data }) => ({
				url: `warehouse/changeStock/${id}`,
				method: 'PUT',
				body: data,
			}),
			invalidatesTags: ['Warehouse', 'WarehouseHistory'],
		}),
		getWarehouse: builder.query<BaseListResponse<Warehouse>, GetWarehouseRequest>({
			query: ({ id }) => ({
				url: `warehouse/warehouse/${id}`,
				method: 'GET',
			}),
			providesTags: ['Warehouse'],
		}),
		getWarehouses: builder.query<BaseListResponse<Warehouse>, ListParams>({
			query: params => ({
				url: parseListParams('warehouse/warehouse', params),
				method: 'GET',
			}),
			providesTags: ['Warehouse'],
		}),
		getShopStock: builder.query<BaseListResponse<GetStockProduct>, GetStockRequest>({
			query: ({ id, ...params }) => ({
				url: parseListParams(`warehouse/getShopStock/${id}`, params),
				method: 'GET',
			}),
			providesTags: ['Warehouse'],
		}),
		getWarehouseHistory: builder.query<BaseDocumentResponse<WarehouseHistory>, GetWarehouseHistoryRequest>({
			query: ({ id }) => ({
				url: `warehouse/history/${id}`,
				method: 'GET',
			}),
			providesTags: ['WarehouseHistory'],
		}),
		getWarehouseHistoryList: builder.query<BaseListResponse<WarehouseHistory>, ListParams>({
			query: params => ({
				url: parseListParams('warehouse/history', params),
				method: 'GET',
			}),
			providesTags: ['WarehouseHistory'],
		}),
	}),
});

export const {
	useChangeStockMutation,
	useGetWarehouseQuery,
	useLazyGetWarehouseQuery,
	useGetWarehousesQuery,
	useLazyGetWarehousesQuery,
	useGetShopStockQuery,
	useLazyGetShopStockQuery,
	useGetWarehouseHistoryListQuery,
	useGetWarehouseHistoryQuery,
	useLazyGetWarehouseHistoryListQuery,
	useLazyGetWarehouseHistoryQuery,
} = warehousesApi;
