const OCLogoIcon = () => (
	<svg width="108" height="31" viewBox="0 0 108 31" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M37.1874 13.3672C37.1874 13.957 37.0896 14.5664 36.9134 15.1757C34.8779 22.0952 22.2733 30.3317 14.9924 30.3907C14.0138 30.4104 13.0548 30.2531 12.1349 29.9779C5.55858 28.0515 0.626354 19.8149 0.58721 13.5638H0.743788H0.900367C0.919939 19.6577 5.81301 27.8156 12.2132 29.683C13.1135 29.9582 14.0334 30.0762 14.9729 30.0762C22.058 30.0565 34.6822 21.7807 36.6003 15.0775C36.7764 14.4877 36.8743 13.9177 36.8547 13.3672C36.8743 8.53149 29.6912 4.22648 22.7235 2.18209C19.8855 1.35647 17.0671 0.884692 14.8358 0.904349C8.33784 0.943665 2.91631 4.93415 1.35053 10.3203C1.05695 11.3622 0.900367 12.4433 0.900367 13.5638H0.743788H0.567637C0.548065 12.404 0.724216 11.2835 1.03737 10.222C2.6423 4.69826 8.20084 0.609486 14.8163 0.570171C17.1062 0.550513 19.9246 1.02229 22.8018 1.86757C29.8087 3.95127 37.1287 8.19731 37.1874 13.3672Z"
			fill="white"
		/>
		<path
			d="M14.9337 31C13.9355 31 12.9373 30.8624 11.9587 30.5675C5.16709 28.5428 0.332729 20.4635 0.0195723 14.1535H0V13.5637V12.9937H0.0195723C0.0587169 11.9911 0.215295 11.0082 0.489308 10.0647C2.1921 4.16741 8.08336 0.0393152 14.8162 0H14.8945C17.1845 0 20.042 0.471782 22.9583 1.31706C29.7499 3.32213 37.6767 7.66646 37.755 13.3475C37.755 13.9765 37.6571 14.6646 37.4614 15.3329C36.4632 18.6944 33.0968 22.5276 28.2428 25.8301C23.5455 29.0146 18.5937 30.941 14.9924 30.9607L14.9337 31ZM1.46792 13.5834C1.50707 19.4217 6.16528 27.324 12.3501 29.1129C13.1917 29.3684 14.0529 29.4864 14.8945 29.4864H14.9532C21.4708 29.4667 34.1341 21.525 36.013 14.9201C36.1696 14.3697 36.2479 13.8586 36.2479 13.3672C36.2675 8.80659 29.1432 4.65885 22.5277 2.7324C19.768 1.92644 17.0475 1.47432 14.875 1.47432H14.7967C8.70968 1.51363 3.40558 5.20926 1.87894 10.4578C1.58536 11.4604 1.44835 12.4826 1.44835 13.5441V13.5637L1.46792 13.5834Z"
			fill="white"
		/>
		<path
			d="M46.5821 12.7185C49.0677 12.7185 50.2616 14.2715 50.2616 16.1193C50.2616 17.9474 49.0482 19.52 46.5821 19.52C44.0964 19.52 42.9025 17.9671 42.9025 16.1193C42.9025 14.2715 44.1355 12.7185 46.5821 12.7185ZM46.5821 13.7407C44.8206 13.7407 44.1942 14.9791 44.1942 16.1193C44.1942 17.2594 44.8401 18.4978 46.5821 18.4978C48.3436 18.4978 48.9699 17.2594 48.9699 16.1193C48.9895 14.9791 48.3436 13.7407 46.5821 13.7407Z"
			fill="white"
		/>
		<path
			d="M56.1725 12.9346H57.5425L54.6654 19.3036H53.3541L50.4769 12.9346H51.8666L54.0391 17.9669L56.1725 12.9346Z"
			fill="white"
		/>
		<path
			d="M58.9909 16.4928C59.0887 17.7902 60.2435 18.5175 61.3395 18.5175C62.0246 18.5175 62.9836 18.2816 63.3751 17.3774H64.6473C64.0405 18.9106 62.6313 19.52 61.3787 19.52C58.893 19.52 57.6991 17.9671 57.6991 16.1193C57.6991 14.2911 58.9126 12.7185 61.3787 12.7185C63.0423 12.7185 64.8039 13.7014 64.8039 16.3158V16.4731L58.9909 16.4928ZM63.5512 15.5295C63.3751 14.3697 62.4943 13.7407 61.32 13.7407C60.2044 13.7407 59.2062 14.3894 59.03 15.5295H63.5512Z"
			fill="white"
		/>
		<path
			d="M66.1153 12.9346H67.3288V14.0944H67.3483C67.9355 13.0919 68.738 12.6987 69.5209 12.6987C69.8144 12.6987 69.9906 12.7184 70.2059 12.7774V14.0748C69.9123 13.9961 69.6774 13.9568 69.3447 13.9568C68.1312 13.9568 67.3092 14.6448 67.3092 16.0405V19.3037H66.0957L66.1153 12.9346Z"
			fill="white"
		/>
		<path
			d="M77.9565 17.0433C77.7999 17.8885 77.0561 19.5005 74.7858 19.5005C72.5154 19.5005 71.2432 18.1244 71.2432 16.1587C71.2432 14.2716 72.3979 12.8169 74.7858 12.8169C76.9583 12.8169 77.7803 14.1733 77.9173 15.0775H77.017C76.743 13.9767 75.8818 13.5639 74.7662 13.5639C72.9851 13.5639 72.1239 14.704 72.1239 16.1783C72.1239 17.7313 73.083 18.7928 74.7662 18.7928C76.0775 18.7928 76.8213 18.0655 77.0561 17.1022L77.9565 17.0433Z"
			fill="white"
		/>
		<path d="M79.2091 10.5562H80.1094L80.129 19.3038H79.2287L79.2091 10.5562Z" fill="white" />
		<path
			d="M84.6697 12.7971C87.0967 12.7971 88.2319 14.3697 88.2319 16.1389C88.2319 17.9081 87.0771 19.4807 84.6697 19.4807C82.2623 19.4807 81.1075 17.9081 81.1075 16.1389C81.1075 14.3697 82.2427 12.7971 84.6697 12.7971ZM84.6697 18.7534C86.3529 18.7534 87.3315 17.6525 87.3315 16.1389C87.3315 14.6253 86.3529 13.5245 84.6697 13.5245C82.9865 13.5245 82.0079 14.6253 82.0079 16.1389C81.9883 17.6525 82.9865 18.7534 84.6697 18.7534Z"
			fill="white"
		/>
		<path
			d="M94.5929 18.0653H94.5733C94.3189 18.7337 93.3599 19.5003 91.9702 19.5003C89.8956 19.5003 89.2301 18.3995 89.2301 16.8858V12.9937H90.1304V17.0628C90.1304 18.3209 90.8742 18.7533 92.0289 18.7533C93.7905 18.7533 94.5733 17.8687 94.5733 16.4337V13.033H95.4737L95.4932 19.3627H94.5929V18.0653Z"
			fill="white"
		/>
		<path
			d="M102.539 18.0458C102.265 18.5766 101.502 19.5201 99.9557 19.5201C97.3917 19.5201 96.5305 17.7116 96.5305 16.1783C96.5305 14.4092 97.5874 12.8366 99.9557 12.8366C101.169 12.8366 102.109 13.4853 102.52 14.3109H102.539V10.5759H103.44L103.459 19.3236H102.539V18.0458ZM99.9361 13.5442C98.5269 13.5442 97.4113 14.5468 97.4113 16.1587C97.4113 17.4168 98.0963 18.7731 99.9361 18.7731C101.424 18.7731 102.578 17.8099 102.578 16.1587C102.578 14.8416 101.776 13.5442 99.9361 13.5442Z"
			fill="white"
		/>
		<path
			d="M106.434 13.7014C105.573 13.7014 104.868 12.9937 104.868 12.1288C104.868 11.2638 105.573 10.5562 106.434 10.5562C107.295 10.5562 108 11.2638 108 12.1288C108 12.9937 107.295 13.7014 106.434 13.7014ZM106.434 10.792C105.71 10.792 105.123 11.3818 105.123 12.1288C105.123 12.8561 105.71 13.4458 106.434 13.4458C107.158 13.4458 107.746 12.8561 107.746 12.1288C107.765 11.4014 107.158 10.792 106.434 10.792Z"
			fill="white"
		/>
		<path
			d="M105.847 11.5193H106.65C106.904 11.5193 107.002 11.6962 107.002 11.8731C107.002 12.0304 106.924 12.1483 106.787 12.1876C106.904 12.2073 106.963 12.3056 106.982 12.4629C107.002 12.6987 107.002 12.7577 107.041 12.8167H106.845C106.806 12.7774 106.806 12.7184 106.806 12.5611C106.787 12.3252 106.708 12.2663 106.552 12.2663H106.043V12.8167H105.847V11.5193ZM106.571 12.0894C106.767 12.0894 106.806 11.9714 106.806 11.8928C106.806 11.7748 106.728 11.6962 106.571 11.6962H106.043V12.109C106.043 12.0894 106.571 12.0894 106.571 12.0894Z"
			fill="white"
		/>
	</svg>
);

export default OCLogoIcon;
