import React from 'react';

interface SelectComponentProps {
	id: string;
	placeholder?: string;
	label?: string;
	error?: string | undefined;
	register: any;
	classes: string;
	isSubmited?: boolean;
	options: { value: string; label: string }[];
}

const SelectComponent: React.FC<SelectComponentProps> = ({
	id,
	placeholder,
	label,
	options,
	error,
	register,
	classes,
	isSubmited,
}) => {
	const inputWrapperClasses = `${classes} input-group`;
	const inputClasses = `form-select w-100 ${error ? 'is-invalid' : isSubmited && !error ? 'is-valid' : ''}`;
	return (
		<>
			<div className={inputWrapperClasses}>
				{label && <label className="form-label text-small ps-2 m-0 w-100">{label}</label>}
				<select className={inputClasses} aria-label="" {...register(id)} placeholder={placeholder}>
					<option value="">Wybierz</option>
					{options.map(option => (
						<option value={option.value} key={id + option.value}>
							{option.label}
						</option>
					))}
				</select>
				{error && <div className="invalid-tooltip">{error}</div>}
			</div>
		</>
	);
};

export default SelectComponent;
