import { useAppDispatch, useAppSelector } from 'app/hooks';
import { ChangeStockProduct } from 'app/types';
import { getConfirmation } from 'app/util';
import backIcon from 'assets/images/back.svg';
import deleteIcon from 'assets/images/delete.svg';
import listekIcon from 'assets/images/listek.svg';
import saveIcon from 'assets/images/save.svg';
import stockIcon from 'assets/images/stockamount.svg';
import { setIsLoading, setToast, ToastType } from 'features/appSlice';
import { get, isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useChangeStockMutation, useGetWarehousesQuery } from 'services/warehouses.service';
import WarehouseCorrections from './WarehouseCorrections.component';
import WarehouseInventory from './WarehouseInventory.component';
import WarehouseStock from './WarehouseStock.component';
import WarehouseTransactions from './WarehouseTransactions.component';

const WarehouseComponent: React.FC = () => {
	const dispatch = useAppDispatch();
	const selectedShop = useAppSelector(state => state.app.selectedShop);
	const [activeTab, setActiveTab] = useState(1);
	const [editEnabled, setEditEnabled] = useState(false);
	const [activeWarehouseId, setActiveWarehouseId] = useState<string>('');
	const [stockChanges, setStockChanges] = useState<ChangeStockProduct[]>([]);
	const [changeStock] = useChangeStockMutation();
	const { data: warehouseData } = useGetWarehousesQuery({ shopId: selectedShop, limit: 1, select: '_id' });

	useEffect(() => {
		if (warehouseData && warehouseData.documents) {
			setActiveWarehouseId(get(warehouseData, 'documents[0]._id', ''));
		}
	}, [warehouseData]);

	const handleSaveStockChange = async () => {
		try {
			if (isEmpty(stockChanges)) {
				dispatch(setToast({ type: ToastType.WARNING, text: 'Nie dokonano żadnych zmian' }));
			} else {
				const confirmed = await getConfirmation('Czy chcesz zapisać zmiany?');
				if (confirmed) {
					dispatch(setIsLoading({ isLoading: true }));
					await changeStock({ id: selectedShop, products: stockChanges }).unwrap();
					setEditEnabled(false);
					setStockChanges([]);
					dispatch(setToast({ type: ToastType.SUCCESS, text: 'Zapisano zmiany' }));
					dispatch(setIsLoading({ isLoading: false }));
				}
			}
		} catch (err) {
			dispatch(setIsLoading({ isLoading: false }));
			dispatch(setToast({ type: ToastType.WARNING, text: 'Wystąpił błąd' }));
		}
	};

	const handleCancelStockChange = async () => {
		if (!isEmpty(stockChanges)) {
			const confirmed = await getConfirmation('Czy chcesz anulować zmianę stanu?');
			if (confirmed) {
				setEditEnabled(false);
				setStockChanges([]);
			}
		} else {
			setEditEnabled(false);
			setStockChanges([]);
		}
	};

	return (
		<>
			<div className="row g-0 ">
				<div className="col-auto mb-3 me-auto">
					<div className="w-auto ">
						<Link to="/cockpit" className="muted-link pb-1 d-inline-block breadcrumb-back">
							<img src={backIcon} />
							&nbsp;
							<span className="text-small align-middle">Kokpit</span>
						</Link>
						<h1 className="mb-0 pb-0 d-flex flex-nowrap align-items-center" id="title">
							<img src={listekIcon} className="logo-leaf-heading mt-3 me-3" />
							Magazyn
						</h1>
					</div>
				</div>
			</div>
			<div className="d-flex flex-wrap align-items-center justify-content-between mb-0">
				<nav className="nav nav-pills mb-0" role="tablist">
					<a
						className="nav-link active"
						id="stanmagazynowy-tab"
						data-bs-toggle="pill"
						href="#stanmagazynowy"
						role="tab"
						aria-controls="stanmagazynowy"
						aria-selected="true"
						onClick={() => setActiveTab(1)}
					>
						Stan magazynowy
					</a>
					<a
						className="nav-link"
						id="pills-profile-tab"
						data-bs-toggle="pill"
						href="#inwentaryzacja"
						role="tab"
						aria-controls="pills-profile"
						aria-selected="false"
						onClick={() => setActiveTab(2)}
					>
						Inwentaryzacje
					</a>
					<a
						className="nav-link"
						id="pills-profile-tab"
						data-bs-toggle="pill"
						href="#historia"
						role="tab"
						aria-controls="pills-profile"
						aria-selected="false"
						onClick={() => setActiveTab(3)}
					>
						Transakcje
					</a>
					<a
						className="nav-link"
						id="pills-profile-tab"
						data-bs-toggle="pill"
						href="#korekty"
						role="tab"
						aria-controls="pills-profile"
						aria-selected="false"
						onClick={() => setActiveTab(4)}
					>
						Korekty
					</a>
				</nav>

				{activeTab === 1 && (
					<div className="d-flex align-items-end justify-content-end col-3 ">
						{editEnabled ? (
							<button
								type="button"
								className="semired btn btn-outline-danger w-50 border-0 px-3"
								data-bs-toggle="tooltip"
								data-bs-placement="top"
								title="Anuluj"
								data-bs-original-title="Anuluj"
								onClick={handleCancelStockChange}
							>
								<img src={deleteIcon} className="m-2" />
								Anuluj
							</button>
						) : (
							<button
								type="submit"
								className="semigreen btn btn-outline-primary w-50 border-0 px-3"
								data-bs-toggle="tooltip"
								data-bs-placement="top"
								title="Edytuj"
								data-bs-original-title="Tooltip on top"
								onClick={() => setEditEnabled(!editEnabled)}
							>
								<img src={stockIcon} width="20" height="20" className="m-2" /> Edytuj stan
							</button>
						)}

						{editEnabled && (
							<button
								type="submit"
								className="semigreen btn btn-outline-primary ms-3 border-0 w-50 px-3"
								data-bs-toggle="tooltip"
								data-bs-placement="top"
								title="Zapisz zmiany"
								data-bs-original-title="Tooltip on top"
								onClick={handleSaveStockChange}
							>
								<img src={saveIcon} width="20" height="20" className="m-2" /> Zapisz
							</button>
						)}
					</div>
				)}
				<hr className="w-100" />
			</div>

			<div className="tab-content" id="pills-tabContent">
				<WarehouseStock
					editEnabled={editEnabled}
					setStockChanges={setStockChanges}
					stockChanges={stockChanges}
				/>
				{activeWarehouseId && <WarehouseInventory warehouseId={activeWarehouseId} />}
				<WarehouseTransactions />
				{activeWarehouseId && <WarehouseCorrections warehouseId={activeWarehouseId} />}
			</div>
		</>
	);
};

export default WarehouseComponent;
