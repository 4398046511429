import React from 'react';
import editIcon from 'assets/images/edit.svg';
import activeIcon from 'assets/images/active.svg';
import adultIcon from 'assets/images/adult.svg';
import deleteIcon from 'assets/images/delete.svg';
import { Product } from 'app/types';
import { get, truncate } from 'lodash';
import { Link } from 'react-router-dom';
import { getConfirmation } from 'app/util';
import { useDeleteProductMutation } from 'services/products.service';
import { useAppDispatch } from 'app/hooks';
import { setIsLoading } from 'features/appSlice';

interface ProductCardProps {
	product: Product;
}

const ProductCard: React.FC<ProductCardProps> = ({ product }: ProductCardProps) => {
	const dispatch = useAppDispatch();
	const [deleteProduct] = useDeleteProductMutation();

	const handleDelete = async () => {
		try {
			const confirmed = await getConfirmation('Czy chcesz usunąć produkt?');
			if (confirmed) {
				dispatch(setIsLoading({ isLoading: true }));
				const res = await deleteProduct({ id: product._id }).unwrap();
				dispatch(setIsLoading({ isLoading: false }));
				if (res.success) {
					//Toast Success
				} else {
					//Toast Error
				}
			}
		} catch (err) {
			dispatch(setIsLoading({ isLoading: false }));
			//Toast Error
		}
	};

	return (
		<div className="card product col-12 ps-0 mb-2 pe-4">
			<div className="row col-12 h-100 g-0 position-relative align-content-center">
				<Link to={`/product/${product._id}`} className="col-1">
					<img
						src={get(product, 'media[0]', '')}
						alt="product"
						className="card-img card-img-horizontal h-100"
					/>
				</Link>
				<Link
					to={`/product/${product._id}`}
					className="col-11 col-lg-3 d-flex flex-column mb-lg-0 mb-3 ps-3 pe-3 justify-content-center"
				>
					{product.name}, {product.capacity}
					<div className="text-small text-muted text-truncate position">EAN: {product.ean}</div>
				</Link>
				<div className="col-12 col-lg-4 d-flex flex-column pe-1 mb-2 mb-lg-0 justify-content-center">
					<div className="text-small pe-3" title={product.description}>
						{product.description ? truncate(product.description, { length: 197 }) : '-'}
					</div>
				</div>
				<div className="col-12 col-lg-1 d-flex flex-column pe-1 mb-2 mb-lg-0 justify-content-center">
					<div>{product.unitPriceNetto} zł</div>
				</div>
				<div className="col-12 col-lg-1 d-flex flex-column pe-1 mb-2 mb-lg-0 justify-content-center">
					<div className="status">
						{product.active && (
							<img
								className="me-1"
								src={activeIcon}
								data-bs-toggle="tooltip"
								data-bs-placement="top"
								title="Aktywny"
								data-bs-original-title="Tooltip on top"
							/>
						)}
						{product.adultOnly && (
							<img
								src={adultIcon}
								data-bs-toggle="tooltip"
								data-bs-placement="top"
								title="Produkt dla dorosłych"
								data-bs-original-title="Tooltip on top"
							/>
						)}
					</div>
				</div>
				<div className="col-12 col-lg-2 d-flex pe-1 mb-2 mb-lg-0 align-items-center justify-content-end flex-wrap">
					<Link to={`/product/${product._id}`}>
						<button
							type="button"
							className="btn btn-outline-primary px-3 m-1 border-0"
							data-bs-toggle="tooltip"
							data-bs-placement="top"
							title="Edytuj produkt"
							data-bs-original-title="Tooltip on top"
						>
							<img src={editIcon} />
						</button>
					</Link>
					<button
						type="button"
						className="btn btn-outline-danger px-3 m-1 border-0"
						data-bs-toggle="tooltip"
						data-bs-placement="top"
						title="Usuń produkt"
						data-bs-original-title="Tooltip on top"
						onClick={handleDelete}
					>
						<img src={deleteIcon} />
					</button>
				</div>
			</div>
		</div>
	);
};

export default ProductCard;
