import React from 'react';
import { User, UserRoleTypes } from 'app/types';
import { get } from 'lodash';
import { Link } from 'react-router-dom';

import activeIcon from 'assets/images/active.svg';
import inactiveIcon from 'assets/images/inactive.svg';
import { useAppSelector } from 'app/hooks';

interface UserAdminCardProps {
	user: User;
}

const UserAdminCard: React.FC<UserAdminCardProps> = ({ user }: UserAdminCardProps) => {
	const loggedUser = useAppSelector(state => state.auth.user);
	return (
		<div className="card admin col-12 ps-0 mb-2 pe-4">
			<div className="row col-12 h-100 g-0 position-relative align-content-center">
				<div className="col-11 col-lg-3 d-flex align-items-center mb-lg-0  mb-3 ps-3 pe-3 justify-content-start">
					<img
						className="me-1"
						src={user?.active ? activeIcon : inactiveIcon}
						data-bs-toggle="tooltip"
						data-bs-placement="top"
						title={user?.active ? 'Aktywny' : 'Nieaktywny'}
						data-bs-original-title="Tooltip on top"
					/>
					&nbsp;&nbsp;&nbsp;&nbsp;
					<Link to={loggedUser?.role === UserRoleTypes.ADMIN ? `/admin/${get(user, '_id', '')}` : ''}>
						<h5 className="m-0 font-light">
							{get(user, 'name', '-')} {get(user, 'surname', '-')}
						</h5>
					</Link>
				</div>
				<div className="col-12 col-lg-3 d-flex flex-column pe-1 ps-5 mb-2 mb-lg-0 justify-content-center">
					<div className="text-alternate my-2">
						{get(user, 'address.postCode', '-')}
						<br />
						ul. {get(user, 'address.street', '-')} {get(user, 'address.houseNumber', '-')}
					</div>
				</div>
				<div className="col-12 col-lg-2 d-flex pe-1 mb-2 mb-lg-0 align-items-center">01.01.2022 10:50</div>
			</div>
		</div>
	);
};

export default UserAdminCard;
