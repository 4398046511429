const ProductIcon = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="18"
		height="18"
		viewBox="0 0 20 20"
		fill="none"
		stroke="#fff"
		strokeWidth="1.5"
		strokeLinecap="round"
		strokeLinejoin="round"
	>
		<path d="M16.5 11.5L14.5586 12.2894C13.6118 12.6743 12.527 12.4622 11.7949 11.7489V11.7489C10.7962 10.7757 9.20383 10.7757 8.20507 11.7489V11.7489C7.47305 12.4622 6.38817 12.6743 5.44139 12.2894L3.5 11.5" />
		<path d="M14 5L15.1555 5.30852C16.0463 5.54637 16.7839 6.17049 17.1659 7.00965V7.00965C17.6884 8.15765 17.6161 9.48873 16.9721 10.5733L16.3962 11.5433C16.2168 11.8454 16.0919 12.1767 16.0271 12.522L15.4588 15.5529C15.1928 16.9718 13.9539 18 12.5102 18H7.48978C6.04613 18 4.80721 16.9718 4.54116 15.5529L3.97288 12.522C3.90813 12.1767 3.78322 11.8454 3.60383 11.5433L3.0279 10.5733C2.38394 9.48873 2.31157 8.15765 2.83414 7.00965V7.00965C3.21614 6.17049 3.95371 5.54637 4.84452 5.30852L6 5" />
		<path d="M6 6.5C6 4.29086 7.5454 2 10 2C12.4546 2 14 4.29086 14 6.5" />
	</svg>
);

export default ProductIcon;
