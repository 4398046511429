import * as yup from 'yup';

export default yup
	.object({
		name: yup.string().required('Pole jest wymagane'),
		shopId: yup.string().required('Pole jest wymagane'),
		shortDescription: yup.string().required('Pole jest wymagane'),
		description: yup.string().required('Pole jest wymagane'),
		capacity: yup.string().required('Pole jest wymagane'),
		unitPriceNetto: yup
			.string()
			.typeError('Nieprawidłowa cena')
			.min(0.1, 'Cena nie może być mniejsza niż grosz')
			.required('Pole jest wymagane'),
		tax: yup.string().oneOf(['0', '5', '8', '23'], 'Pole jest wymagane').required('Pole jest wymagane'),
		adultOnly: yup.boolean().required('Pole jest wymagane'),
		active: yup.boolean().required('Pole jest wymagane'),
		ean: yup.string().required('Pole jest wymagane'),
		ingredients: yup.array().of(yup.string().min(1, 'Pole nie może być puste')).required('Pole jest wymagane'),
		photos: yup.array().of(yup.mixed()),
	})
	.required();
