import React from 'react';
import { get } from 'lodash';
import { Link } from 'react-router-dom';
import { Shop } from 'app/types';

interface ShopCardProps {
	shop: Shop;
}

const ShopCard: React.FC<ShopCardProps> = ({ shop }: ShopCardProps) => {
	return (
		<>
			<div className="card shop col-12 ps-0 mb-2 pe-4">
				<div className="row col-12 h-100 g-0 position-relative align-content-center">
					<Link
						to={`/shops/${shop._id}`}
						className="col-11 col-lg-3 d-flex align-items-center mb-lg-0  mb-3 ps-3 pe-3 justify-content-start"
					>
						<h5 className="m-0 font-light">{get(shop, 'name', '-')}</h5>
					</Link>
					<div className="col-12 col-lg-3 d-flex flex-column pe-1 ps-5 mb-2 mb-lg-0 justify-content-center">
						<div className="text-alternate my-2">
							{get(shop, 'address.postCode', '')} {get(shop, 'address.locality', '')}
							<br />
							ul. {get(shop, 'address.street', '')} {get(shop, 'address.houseNumber', '')}
						</div>
					</div>
					{/* <div className="col-12 col-lg-2 d-flex pe-1 mb-2 mb-lg-0 justify-content-start align-items-center">
						4
					</div>
					<div className="col-12 col-lg-2 d-flex flex-column pe-1 mb-2 mb-lg-0 justify-content-center">9</div>
					<div className="col-12 col-lg-2 d-flex pe-1 mb-2 mb-lg-0 align-items-center justify-content-start flex-wrap">
						729,95 zł
					</div> */}
				</div>
			</div>
		</>
	);
};

export default ShopCard;
