import { UserRoleTypes } from 'app/types';
import * as yup from 'yup';

export default yup
	.object({
		edit: yup.boolean(),
		name: yup.string().required('Pole jest wymagane'),
		surname: yup.string().required('Pole jest wymagane'),
		active: yup.boolean(),
		email: yup.string().email('Nieprawidłowy email').required('Pole jest wymagane'),
		role: yup
			.string()
			.oneOf([UserRoleTypes.ADMIN, UserRoleTypes.OWNER], 'Nieprawidłowa rola')
			.required('Pole jest wymagane'),
		password: yup.string().when('edit', {
			is: false,
			then: yup.string().required('Hasło jest wymagane'),
			otherwise: yup.string(),
		}),
		passwordConfirm: yup.string().when('edit', {
			is: false,
			then: yup.string().required('Powtórz hasło'),
			otherwise: yup.string(),
		}),
	})
	.required();
