import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery, ListParams, parseListParams } from 'app/api';
import {
	BaseDocumentResponse,
	BaseListResponse,
	CreateShopRequest,
	Shop,
	GetShopRequest,
	EditShopRequest,
} from '../app/types';

export const shopsApi = createApi({
	baseQuery,
	reducerPath: 'shopsApi',
	endpoints: builder => ({
		createShop: builder.mutation<BaseDocumentResponse<Shop>, CreateShopRequest>({
			query: credentials => ({
				url: 'shop/shop',
				method: 'POST',
				body: credentials,
			}),
		}),
		editShop: builder.mutation<BaseDocumentResponse<Shop>, EditShopRequest>({
			query: ({ id, data }) => ({
				url: `shop/shop/${id}`,
				method: 'PUT',
				body: data,
			}),
		}),
		getShops: builder.query<BaseListResponse<Shop>, ListParams>({
			query: params => ({
				url: parseListParams('shop/shop', params),
				method: 'GET',
			}),
		}),
		getShop: builder.query<BaseDocumentResponse<Shop>, GetShopRequest>({
			query: ({ id }) => ({
				url: `shop/shop/${id}`,
				method: 'GET',
			}),
		}),
	}),
});

export const {
	useCreateShopMutation,
	useEditShopMutation,
	useGetShopsQuery,
	useLazyGetShopsQuery,
	useLazyGetShopQuery,
} = shopsApi;
