const deleteIconStr =
	'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCIgdmlld0JveD0iMCAwIDIwIDIwIiBmaWxsPSJub25lIiBzdHJva2U9IiM1NTU1NTUiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiPjxwYXRoIGQ9Ik00IDVWMTQuNUM0IDE1LjkwNDUgNCAxNi42MDY3IDQuMzM3MDYgMTcuMTExMUM0LjQ4Mjk4IDE3LjMyOTUgNC42NzA0OCAxNy41MTcgNC44ODg4NiAxNy42NjI5QzUuMzkzMzEgMTggNi4wOTU1NCAxOCA3LjUgMThIMTIuNUMxMy45MDQ1IDE4IDE0LjYwNjcgMTggMTUuMTExMSAxNy42NjI5QzE1LjMyOTUgMTcuNTE3IDE1LjUxNyAxNy4zMjk1IDE1LjY2MjkgMTcuMTExMUMxNiAxNi42MDY3IDE2IDE1LjkwNDUgMTYgMTQuNVY1Ij48L3BhdGg+PHBhdGggZD0iTTE0IDVMMTMuOTQyNCA0Ljc0MDc0QzEzLjY5MzQgMy42MjA0MyAxMy41NjkgMy4wNjAyOCAxMy4yMjUgMi42NzI2NkMxMy4wNzUxIDIuNTAzNjggMTIuODk3NyAyLjM2MTMzIDEyLjcwMDIgMi4yNTE2NEMxMi4yNDcyIDIgMTEuNjczNCAyIDEwLjUyNTcgMkw5LjQ3NDI3IDJDOC4zMjY2MyAyIDcuNzUyODEgMiA3LjI5OTgxIDIuMjUxNjRDNy4xMDIzNCAyLjM2MTMzIDYuOTI0ODggMi41MDM2OCA2Ljc3NDk2IDIuNjcyNjZDNi40MzEwNSAzLjA2MDI4IDYuMzA2NTcgMy42MjA0NCA2LjA1NzYxIDQuNzQwNzRMNiA1Ij48L3BhdGg+PHBhdGggZD0iTTIgNUgxOE0xMiA5VjEzTTggOVYxMyI+PC9wYXRoPjwvc3ZnPg==';

const previewTemplate = `
<div class="dz-preview dz-file-preview col border-0 h-auto me-0">
    <div class="d-flex flex-column border rounded-md">
        <div class="p-0 position-relative image-container w-100">
            <div class="preview-container rounded-0 rounded-md-top">
                <img data-dz-thumbnail class="img-thumbnail border-0 rounded-0 rounded-md-top sh-18" />
            </div>
        </div>
        <div class="ps-3 pt-3 pe-2 pb-1 dz-details position-relative w-100">
            <div><span data-dz-name></span></div>
            <div class="text-primary text-extra-small" data-dz-size />
            <div class="dz-error-message"><span data-dz-errormessage></span></div>
        </div>
    </div>
    <a href="#/" class="remove" data-dz-remove><img src='${deleteIconStr}' /></a>
</div>`;

export default previewTemplate;
