import React from 'react';

const ConfirmModalComponent: React.FC = () => {
	return (
		<>
			<button
				className="btn text-muted  me-auto px-3 text-danger rounded-pill bg-outline-red d-none"
				data-bs-toggle="modal"
				data-bs-target="#confirmModal"
				id="confirmBtn"
			>
				Modal
			</button>

			<div
				id="confirmModal"
				className="modal"
				tabIndex={-1}
				role="dialog"
				aria-labelledby="confirmModal"
				aria-modal="true"
			>
				<div className="modal-dialog modal-md modal-dialog-scrollable" role="document">
					<div className="modal-content radius border-0">
						<div className="modal-header">
							<h5 className="modal-title" id="confirmModalTitle">
								Czy jesteś pewny?
							</h5>
							<button type="button" className="btn close" data-bs-dismiss="modal" aria-label="Zamknij">
								<span aria-hidden="true">x</span>
							</button>
						</div>
						<div className="modal-footer d-flex justify-content-center">
							<button
								type="button"
								className="btn btn-secondary"
								data-bs-dismiss="modal"
								id="confirmReject"
							>
								Anuluj
							</button>
							<button
								type="button"
								className="btn btn-primary"
								id="confirmAccept"
								data-bs-dismiss="modal"
							>
								Zatwierdź
							</button>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default ConfirmModalComponent;
