import { useAppDispatch } from 'app/hooks';
import { VerifyIdentityRequest } from 'app/types';
import { setToast, ToastType } from 'features/appSlice';
import { useEffect, useState } from 'react';
import { useLazyGetBackPhotoQuery, useLazyGetFrontPhotoQuery, useVerifyIdentityMutation } from 'services/users.service';

interface AdultVerificationModalProps {
	id: string | null | undefined;
	onVerifyEnd: () => void;
}

const AdultVerificationModalComponent: React.FC<AdultVerificationModalProps> = ({
	id,
	onVerifyEnd,
}: AdultVerificationModalProps) => {
	const dispatch = useAppDispatch();
	const [isLoading, setIsLoading] = useState(false);
	const [rejectReason, setRejectReason] = useState<string>();
	const [isApproved, setIsApproved] = useState<boolean>(true);
	const [frontPhoto, setFrontPhoto] = useState<any>();
	const [backPhoto, setBackPhoto] = useState<any>();
	const [getFrontPhoto] = useLazyGetFrontPhotoQuery();
	const [getBackPhoto] = useLazyGetBackPhotoQuery();
	const [verifyIdentity] = useVerifyIdentityMutation();

	useEffect(() => {
		const loadFrontPhoto = async () => {
			try {
				if (id) {
					const blob = await getFrontPhoto({ id }).unwrap();

					if (blob) {
						setFrontPhoto(URL.createObjectURL(blob));
					} else {
						dispatch(setToast({ type: ToastType.WARNING }));
					}
				}
			} catch (err) {
				dispatch(setToast({ type: ToastType.WARNING }));
			}
		};

		const loadBackPhoto = async () => {
			try {
				if (id) {
					const blob = await getBackPhoto({ id }).unwrap();

					if (blob) {
						setBackPhoto(URL.createObjectURL(blob));
					} else {
						dispatch(setToast({ type: ToastType.WARNING }));
					}
				}
			} catch (err) {
				dispatch(setToast({ type: ToastType.WARNING }));
			}
		};

		const loadBothPhotos = async () => {
			setIsLoading(true);
			await Promise.all([loadFrontPhoto(), loadBackPhoto()]);
			setIsLoading(false);
		};
		loadBothPhotos();
	}, [id]);

	const handleSave = async () => {
		try {
			if (id) {
				const data: VerifyIdentityRequest = {
					status: isApproved ? 'approved' : 'denied',
					id,
				};

				if (!isApproved) {
					data.comment = rejectReason;
				}

				const res = await verifyIdentity(data).unwrap();

				if (res && res.success) {
					dispatch(setToast({ type: ToastType.SUCCESS }));
				} else {
					dispatch(setToast({ type: ToastType.WARNING }));
				}

				onVerifyEnd();
			}
		} catch (error) {
			dispatch(setToast({ type: ToastType.WARNING }));
		}
	};

	return (
		<div id="verifyModal" className="modal" role="dialog" aria-labelledby="verifyModal" aria-hidden="true">
			<div className="modal-dialog modal-lg modal-dialog-scrollable" role="document">
				<div className="modal-content radius border-0">
					<div className="modal-header">
						<h5 className="modal-title" id="exampleModalCenteredLabel">
							Zweryfikuj uzytkownika
						</h5>
						<button type="button" className="btn close" data-bs-dismiss="modal" aria-label="Zamknij">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div className="modal-body">
						<label className="form-label w-100 text-small ps-2">Przód dokumentu</label>
						<div className="document-front mb-3">
							{isLoading ? (
								<p className="text-center mt-2">Ładowanie zdjęcia ...</p>
							) : (
								<img className="w-100 radius" src={frontPhoto} />
							)}
						</div>
						<label className="form-label w-100 text-small ps-2">Tył dokumentu</label>
						{isLoading ? (
							<p className="text-center mt-2">Ładowanie zdjęcia ...</p>
						) : (
							<img className="w-100 radius" src={backPhoto} />
						)}
						<hr />
						<div className="decision d-flex justify-content-center">
							<input
								type="radio"
								className="btn-check"
								name="options-outlined"
								id="success-outlined"
								checked={isApproved}
								onChange={() => setIsApproved(true)}
							/>
							<label className="btn btn-outline-success mx-1" htmlFor="success-outlined">
								Potwierdź pełnoletność
							</label>

							<input
								type="radio"
								className="btn-check"
								name="options-outlined"
								id="danger-outlined"
								onChange={() => setIsApproved(false)}
							/>
							<label className="btn btn-outline-danger mx-1" htmlFor="danger-outlined">
								Odrzuć weryfikację
							</label>
						</div>
						{!isApproved && (
							<div id="reject">
								<div className="card card-body">
									<label className="form-label text-small ps-2">Podaj uzasadnienie odrzucenia</label>
									<textarea
										className="form-control w-100"
										placeholder="Dodaj opis produktu"
										id="floatingTextarea"
										value={rejectReason}
										onChange={event => setRejectReason(event.target.value)}
									></textarea>
								</div>
							</div>
						)}
					</div>
					<div className="modal-footer d-flex justify-content-center">
						<button
							type="button"
							className="btn btn-secondary"
							data-bs-dismiss="modal"
							onClick={onVerifyEnd}
						>
							Anuluj
						</button>
						<button type="button" className="btn btn-primary" onClick={handleSave} data-bs-dismiss="modal">
							Zapisz
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AdultVerificationModalComponent;
