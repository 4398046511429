import backIcon from 'assets/images/back.svg';
import listekIcon from 'assets/images/listek.svg';
import logoIcon from 'assets/images/logo.svg';
import plusIcon from 'assets/images/plus.svg';
import PaginationComponent from 'components/Pagination.component';
import { get, isArray, isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useGetShopsQuery } from 'services/shops.service';
import ShopCard from './ShopCard.component';

const ShopsComponent: React.FC = () => {
	const navigate = useNavigate();
	const [currentPage, setCurrentPage] = useState(1);
	const [limit, setLimit] = useState(10);
	const { data, refetch } = useGetShopsQuery({
		limit,
		offset: (currentPage - 1) * limit,
	});

	useEffect(() => {
		setCurrentPage(1);
		refetch();
	}, [limit]);

	useEffect(() => {
		refetch();
	}, [currentPage]);

	const renderList = () => {
		if (isArray(data?.documents) && !isEmpty(data?.documents)) {
			return data?.documents.map(shop => <ShopCard shop={shop} key={shop._id} />);
		}
		return (
			<div className="empty-list col-sm-12 p-5 justify-content-center d-flex flex-wrap">
				<img src={logoIcon} />
				<h4 className="text-muted w-100 text-center mt-4">Brak sklepów do wyświetlenia.</h4>
			</div>
		);
	};

	return (
		<>
			<div className="row g-0">
				<div className="col-auto mb-3 me-auto">
					<div className="w-auto ">
						<Link to="/cockpit" className="muted-link pb-1 d-inline-block breadcrumb-back">
							<img src={backIcon} />
							<span className="text-small align-middle">&nbsp;Kokpit</span>
						</Link>
						<h1 className="mb-0 pb-0 d-flex flex-nowrap align-items-center" id="title">
							<img src={listekIcon} className="logo-leaf-heading mt-3 me-3" />
							Sklepy
						</h1>
					</div>
				</div>
			</div>

			<div className="tab-content" id="pills-tabContent">
				<div
					className="tab-pane fade show active"
					id="customers"
					role="tabpanel"
					aria-labelledby="pills-home-tab"
				>
					<div className="row mb-2">
						<button
							type="button"
							className="btn btn-outline-primary w-auto px-3 ms-3 me-auto"
							onClick={() => navigate('/shop')}
						>
							<img src={plusIcon} />
							<span>&nbsp;Dodaj sklep</span>
						</button>
						<div className="col-sm-12 col-md-7 col-lg-9 col-xxl-10 text-end d-flex justify-content-end mb-1">
							<select
								className="form-select shadow justify-self-end pe-5"
								aria-label="Pokaż"
								value={limit}
								onChange={e => setLimit(parseInt(e.target.value))}
							>
								<option value="10">10 produktów</option>
								<option value="25">25 produktów</option>
								<option value="50">50 produktów</option>
							</select>
						</div>
					</div>
					<hr />
					<div id="customers-list" className="d-flex flex-wrap gx-4 gy-0">
						{get(data, 'total', 0) > 0 && (
							<div
								id="product-list-heading"
								className="g-0 mb-4 mb-lg-3 pe-4 d-flex col-12 align-content-center"
							>
								<div className="col-3 col-lg-3 ps-3">
									<div className="text-muted text-small">NAZWA</div>
								</div>
								<div className="col-3 col-lg-3 ps-5">
									<div className="text-muted text-small">ADRES</div>
								</div>
							</div>
						)}

						{renderList()}

						<div className="w-100 d-flex mt-2 justify-content-center">
							<PaginationComponent
								currentPage={currentPage}
								totalCount={data?.total || 0}
								pageSize={limit}
								onPageChange={setCurrentPage}
								siblingCount={2}
							/>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default ShopsComponent;
