import { useEffect, useState } from 'react';
import { useGetUsersQuery } from 'services/users.service';
import { isArray, isEmpty } from 'lodash';
import { User, UserRoleTypes } from 'app/types';

import UserAdminCard from './UserAdminCard.component';
import PaginationComponent from 'components/Pagination.component';

const UsersToApproveListComponent: React.FC = () => {
	const [currentPage, setCurrentPage] = useState(1);
	const [limit, setLimit] = useState(10);
	const { data, refetch } = useGetUsersQuery({
		limit,
		offset: (currentPage - 1) * limit,
		'role[in]': `${UserRoleTypes.ADMIN},${UserRoleTypes.OWNER}`,
		'sort[name]': 1,
	});

	useEffect(() => {
		setCurrentPage(1);
		refetch();
	}, [limit]);

	useEffect(() => {
		refetch();
	}, [currentPage]);

	const renderList = () => {
		if (isArray(data?.documents) && !isEmpty(data?.documents)) {
			return data?.documents.map((user: User) => <UserAdminCard user={user} key={user._id} />);
		}
		return null;
	};

	return (
		<>
			<div className="tab-pane fade" id="admins" role="tabpanel" aria-labelledby="admins-tab">
				<div className="row mb-2">
					<div className="col-sm-12 col-lg-12 text-end d-flex justify-content-end mb-1">
						<select
							className="form-select shadow justify-self-end pe-5"
							aria-label="Pokaż"
							value={limit}
							onChange={e => setLimit(parseInt(e.target.value))}
						>
							<option value="10">10 pozycji</option>
							<option value="25">25 pozycji</option>
							<option value="50">50 pozycji</option>
						</select>
					</div>
				</div>
				<div id="customers-list" className="d-flex flex-wrap gx-4 gy-0">
					<div id="product-list-heading" className="g-0 mb-2 mb-lg-2 pe-4 d-flex col-12 align-content-center">
						<div className="col-3 col-lg-3 ps-3">
							<div className="text-muted text-small">NAZWA</div>
						</div>
						<div className="col-2 col-lg-3 ps-5">
							<div className="text-muted text-small">SKLEPY</div>
						</div>
						<div className="col-2 col-lg-2">
							<div className="text-muted text-small">UTWORZONO</div>
						</div>
					</div>

					{renderList()}

					<div className="w-100 d-flex mt-2 justify-content-center">
						<PaginationComponent
							currentPage={currentPage}
							totalCount={data?.total || 0}
							pageSize={limit}
							onPageChange={setCurrentPage}
							siblingCount={2}
						/>
					</div>
				</div>
			</div>
		</>
	);
};

export default UsersToApproveListComponent;
