const TransactionIcon = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="18"
		height="18"
		viewBox="0 0 20 20"
		fill="none"
		stroke="#fff"
		strokeWidth="1.5"
		strokeLinecap="round"
		strokeLinejoin="round"
	>
		<path d="M13.7152 4C15.3994 4 16.2415 4 16.7847 4.42543C17.0181 4.60826 17.208 4.84069 17.3405 5.10592C17.649 5.72312 17.4809 6.54828 17.1447 8.19862L16.7373 10.1986C16.5044 11.3422 16.3879 11.9139 16.0437 12.3107C15.8937 12.4836 15.7151 12.6294 15.5158 12.7419C15.0583 13 14.4748 13 13.3078 13L9.8076 13C8.65996 13 8.08615 13 7.63315 12.7484C7.43567 12.6387 7.25821 12.4963 7.1083 12.3273C6.76438 11.9397 6.6399 11.3796 6.39095 10.2593L5.9465 8.25926C5.57521 6.58845 5.38957 5.75305 5.69441 5.12578C5.82533 4.85642 6.01506 4.61989 6.24962 4.43366C6.79581 4 7.65159 4 9.36316 4L11.5 4L13.7152 4Z" />
		<path d="M6 8.5 5.05341 4.24035C5.01794 4.08072 4.96302 3.92604 4.88989 3.77978L4.82918 3.65836C4.321 2.64201 3.28221 2 2.1459 2H2M10 7V10M14 7V10" />
		<path d="M7 15.5C7 14.6716 7.67158 14 8.5 14V14C9.32843 14 10 14.6716 10 15.5V15.5C10 16.3284 9.32842 17 8.5 17V17C7.67157 17 7 16.3284 7 15.5V15.5zM13 15.5C13 14.6716 13.6716 14 14.5 14V14C15.3284 14 16 14.6716 16 15.5V15.5C16 16.3284 15.3284 17 14.5 17V17C13.6716 17 13 16.3284 13 15.5V15.5z" />
	</svg>
);

export default TransactionIcon;
