import { Link, useNavigate } from 'react-router-dom';
import plusIcon from 'assets/images/plus.svg';
import backIcon from 'assets/images/back.svg';
import listekIcon from 'assets/images/listek.svg';
import UsersToApproveListComponent from './UsersToApproveList.component';
import UsersAdminListComponent from './UsersAdminList.component';
import UsersListComponent from './UsersList.component';
import { useState } from 'react';
import { useAppSelector } from 'app/hooks';
import { get } from 'lodash';
import { UserRoleTypes } from 'app/types';

const UsersComponent: React.FC = () => {
	const navigate = useNavigate();
	const loggedUser = useAppSelector(state => state.auth.user);
	const verifyCount = useAppSelector(state => state.app.verifyCount);
	return (
		<>
			<div className="row g-0">
				<div className="col-auto mb-3 me-auto">
					<div className="w-auto ">
						<Link to="/cockpit" className="muted-link pb-1 d-inline-block breadcrumb-back">
							<img src={backIcon} />
							&nbsp;
							<span className="text-small align-middle">Kokpit</span>
						</Link>
						<h1 className="mb-0 pb-0 d-flex flex-nowrap align-items-center" id="title">
							<img src={listekIcon} className="logo-leaf-heading mt-3 me-3" />
							Użytkownicy
						</h1>
					</div>
				</div>
				{loggedUser?.role === UserRoleTypes.ADMIN && (
					<div className="col-12 col-sm-6 col-md-auto d-flex align-items-end justify-content-end mb-3 order-sm-3">
						<button
							type="button"
							className="semigreen border-0 btn btn-outline-primary w-100 px-3"
							onClick={() => navigate('/admin')}
						>
							<img src={plusIcon} />
							&nbsp;
							<span>Dodaj użytkownika</span>
						</button>
					</div>
				)}
			</div>
			<div className="d-flex flex-wrap mb-0">
				<nav className="nav nav-pills mb-0" role="tablist">
					<a
						className="nav-link active"
						id="customers-tab"
						data-bs-toggle="pill"
						href="#customers"
						role="tab"
						aria-controls="customers"
						aria-selected="true"
					>
						Klienci
					</a>
					<a
						className="nav-link"
						id="pills-profile-tab"
						data-bs-toggle="pill"
						href="#customers-accept"
						role="tab"
						aria-controls="pills-profile"
						aria-selected="false"
					>
						Do zatwierdzenia <span className="badge ms-1 bg-secondary">{verifyCount}</span>
					</a>
					<a
						className="nav-link"
						id="pills-profile-tab"
						data-bs-toggle="pill"
						href="#admins"
						role="tab"
						aria-controls="pills-profile"
						aria-selected="false"
					>
						Administratorzy
					</a>
				</nav>
				<hr className="w-100" />
			</div>

			<div className="tab-content" id="pills-tabContent">
				<UsersListComponent />
				<UsersToApproveListComponent />
				<UsersAdminListComponent />
			</div>
		</>
	);
};

export default UsersComponent;
