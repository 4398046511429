import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export interface ListParams {
	[field: string]: string | number | boolean;
}

// export const baseUrl = 'http://localhost:3001/';
export const baseUrl = 'https://deligo-api.overcloud.usermd.net/';

export const baseQuery = fetchBaseQuery({
	baseUrl,
	prepareHeaders: headers => {
		const accessToken = getAccessToken();
		if (accessToken) {
			headers.set('authorization', `Bearer ${accessToken}`);
		}
		return headers;
	},
});

export const getAccessToken = (): string | null => localStorage.getItem('accessToken');

export const parseListParams = (url: string, params: ListParams): string => {
	const parsed: string[] = [];
	Object.keys(params).forEach((param: string) => {
		parsed.push(`${param}=${params[param]}`);
	});
	return parsed.length > 0 ? `${url}?${parsed.join('&')}` : url;
};

export const setFormData = (obj: Record<string, any>, form?: FormData, namespace?: string) => {
	const fd = form || new FormData();
	let formKey;
	for (const property in obj) {
		// eslint-disable-next-line no-prototype-builtins
		if (obj.hasOwnProperty(property)) {
			if (namespace) {
				formKey = namespace + '[' + property + ']';
			} else {
				formKey = property;
			}
			if (typeof obj[property] === 'object' && !(obj[property] instanceof File)) {
				setFormData(obj[property], fd, formKey);
			} else {
				fd.append(formKey, obj[property]);
			}
		}
	}
	return fd;
};
