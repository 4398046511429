import { useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import LoginComponent from 'pages/auth/Login.component';
import SidebarComponent from 'components/Sidebar.component';
import CockpitComponent from 'pages/cockpit/Cockpit.component';
import ShopsComponent from 'pages/shop/Shops.component';
import ShopComponent from 'pages/shop/Shop.component';
import LayoutComponent from 'components/Layout.component';
import ConfirmModalComponent from 'components/ConfirmModal.component';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import AppLoaderComponent from 'components/AppLoader.component';
import { useIsAuthQuery } from 'services/auth.service';

import 'assets/styles/style.scss';
import { setIsLoading } from 'features/appSlice';
import { setCredentials } from 'features/authSlice';
import ProductsComponent from 'pages/product/Products.component';
import ProductForm from 'pages/product/ProductForm.component';
import TransactionsComponent from 'pages/transaction/Transactions.component';
import { TransactionDetails } from 'pages/transaction/TransactionDetails.component';
import ToastComponent from 'components/Toast.component';
import WarehouseComponent from 'pages/warehouse/Warehouse.component';
import WarehouseHistoryDetails from 'pages/warehouse/WarehouseHistoryDetails.component';
import UsersComponent from 'pages/user/Users.component';
import UserComponent from 'pages/user/User.component';
import { UserRoleTypes } from 'app/types';
import AdminFormComponent from 'pages/user/AdminForm.component';

function App() {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const user = useAppSelector(state => state.auth.user);
	const { data: isAuth, isLoading: isAuthLoading } = useIsAuthQuery();
	const loggedUser = useAppSelector(state => state.auth.user);

	// IF APP IS REFRESH
	useEffect(() => {
		// SHOW APP LOADING IF ISAUTH REQUEST
		setIsLoading({ isLoading: isAuthLoading });

		if (isAuth) {
			dispatch(setCredentials(isAuth));
			navigate('/cockpit');
			return;
		}

		if (!isAuth && !isAuthLoading) {
			navigate('/login');
		}
	}, [isAuth, isAuthLoading]);

	// LOGIN IN IS SUCCESS
	useEffect(() => {
		if (loggedUser) {
			navigate('/cockpit');
		}
	}, [loggedUser]);

	return (
		<div className="wrapper d-flex align-items-stretch">
			{loggedUser && <SidebarComponent />}
			<ConfirmModalComponent />
			<ToastComponent />

			<Routes>
				<Route path="/login" element={<LoginComponent />} />
				<Route path="/" element={<LayoutComponent />}>
					<Route path="cockpit" element={<CockpitComponent />} />
					{/* USERS MODULE */}
					{user?.role === UserRoleTypes.ADMIN && (
						<>
							<Route path="users" element={<UsersComponent />} />
							<Route path="admin/:id" element={<AdminFormComponent />} />
							<Route path="admin" element={<AdminFormComponent />} />
							<Route path="users/:id" element={<UserComponent />} />
						</>
					)}
					{/* SHOPS MODULE */}
					{user?.role === UserRoleTypes.ADMIN && (
						<>
							<Route path="shops" element={<ShopsComponent />} />
							<Route path="shop" element={<ShopComponent />} />
							<Route path="shops/:id" element={<ShopComponent />} />
						</>
					)}
					{/* Products */}
					<Route path="products" element={<ProductsComponent />} />
					<Route path="product" element={<ProductForm />} />
					<Route path="product/:id" element={<ProductForm />} />

					{/* Transactions */}
					<Route path="transactions" element={<TransactionsComponent />} />
					<Route path="transaction/:id" element={<TransactionDetails />} />

					{/* Warehouse */}
					<Route path="warehouse" element={<WarehouseComponent />} />
					<Route path="warehouse/:type/:id" element={<WarehouseHistoryDetails />} />
				</Route>
			</Routes>
			<AppLoaderComponent />
		</div>
	);
}
export default App;
