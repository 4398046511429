import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery, ListParams, parseListParams } from 'app/api';
import { BaseDocumentResponse, BaseListResponse, GetOrderRequest, Order } from '../app/types';

export const ordersApi = createApi({
	baseQuery,
	reducerPath: 'ordersApi',
	endpoints: builder => ({
		getUserLastOrders: builder.query<BaseListResponse<Order>, ListParams>({
			query: params => ({
				url: parseListParams('order/order', params),
				method: 'GET',
			}),
		}),
		getOrders: builder.query<BaseListResponse<Order>, ListParams>({
			query: params => ({
				url: parseListParams('order/order', params),
				method: 'GET',
			}),
		}),
		getOrder: builder.query<BaseDocumentResponse<Order>, GetOrderRequest>({
			query: ({ id }) => ({
				url: `order/order/${id}`,
				method: 'GET',
			}),
		}),
	}),
});

export const {
	useGetOrdersQuery,
	useLazyGetUserLastOrdersQuery,
	useLazyGetOrdersQuery,
	useGetOrderQuery,
	useLazyGetOrderQuery,
} = ordersApi;
