import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export enum ToastType {
	WARNING = 'warning',
	SUCCESS = 'success',
}

type Toast = {
	type: ToastType;
	text?: string;
} | null;

type AppState = {
	isLoading: boolean;
	selectedShop: string;
	toast: Toast;
	verifyCount: number;
};

const initialState: AppState = {
	isLoading: false,
	selectedShop: '',
	toast: null,
	verifyCount: 0,
};

const appSlice = createSlice({
	name: 'app',
	initialState,
	reducers: {
		setIsLoading: (state, { payload: { isLoading } }: PayloadAction<Pick<AppState, 'isLoading'>>) => {
			state.isLoading = isLoading;
		},
		setSelectedShop: (state, { payload: { id } }: PayloadAction<{ id: string }>) => {
			localStorage.setItem('@selectedShop', id);
			state.selectedShop = id;
		},
		setToast: (state, { payload: { type, text } }: PayloadAction<{ type: ToastType; text?: string }>) => {
			state.toast = { type, text };
		},
		setVerifyCount: (state, { payload: { verifyCount } }: PayloadAction<Pick<AppState, 'verifyCount'>>) => {
			state.verifyCount = verifyCount;
		},
		clearToast: state => {
			state.toast = null;
		},
	},
});

export const { setIsLoading, setSelectedShop, setToast, clearToast, setVerifyCount } = appSlice.actions;

export default appSlice.reducer;
