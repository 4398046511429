import { usePagination } from 'app/hooks';
import React from 'react';

interface PaginationComponentProps {
	totalCount: number;
	currentPage: number;
	pageSize: number;
	onPageChange: (newPage: number) => void;
	siblingCount: number;
}

const PaginationComponent: React.FC<PaginationComponentProps> = ({
	totalCount,
	currentPage,
	pageSize,
	onPageChange,
	siblingCount,
}) => {
	const nrOfPages = Math.ceil(totalCount / pageSize);
	const paginationRange = usePagination({
		currentPage,
		totalCount,
		siblingCount,
		pageSize,
	});
	if (paginationRange === undefined) return null;

	if (currentPage === 0 || paginationRange.length < 2) {
		return null;
	}

	const onNext = () => {
		if (currentPage < nrOfPages) onPageChange(currentPage + 1);
	};

	const onPrevious = () => {
		if (currentPage > 1) onPageChange(currentPage - 1);
	};

	return (
		<nav>
			<ul className="pagination">
				<li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`} onClick={onPrevious}>
					<button className="page-link shadow">{'<'}</button>
				</li>
				{paginationRange.map((pageNumber: number) => {
					return (
						<li
							className={`page-item ${pageNumber === currentPage ? 'active' : ''}`}
							onClick={() => onPageChange(pageNumber)}
							key={`page${pageNumber}`}
						>
							<button className="page-link shadow">{pageNumber}</button>
						</li>
					);
				})}
				<li className="page-item" onClick={onNext}>
					<button className="page-link shadow">{'>'}</button>
				</li>
			</ul>
		</nav>
	);
};

export default PaginationComponent;
