import * as yup from 'yup';

export default yup
	.object({
		name: yup.string().required('Pole jest wymagane'),
		surname: yup.string().required('Pole jest wymagane'),
		active: yup.boolean().required('Pole jest wymagane'),
		verifiedAdult: yup.boolean().required('Pole jest wymagane'),
		commercialInformationAgreement: yup.boolean().required('Pole jest wymagane'),
		dataProcessingAgreement: yup.boolean().required('Pole jest wymagane'),
		password: yup.string(),
		passwordConfirm: yup.string(),
	})
	.required();
