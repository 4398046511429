import { useMemo } from 'react';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import type { RootState, AppDispatch } from './store';

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

interface UsePaginationProps {
	totalCount: number;
	currentPage: number;
	pageSize: number;
	siblingCount: number;
}

export const usePagination = ({ totalCount, pageSize, siblingCount = 1, currentPage }: UsePaginationProps) => {
	const range = (start: number, end: number) => {
		const length: number = end - start + 1;
		return Array.from({ length }, (_, idx) => idx + start);
	};

	const paginationRange = useMemo(() => {
		const totalPageCount = Math.ceil(totalCount / pageSize);
		const totalPageNumbers = siblingCount + 5;
		if (totalPageNumbers >= totalPageCount) {
			return range(1, totalPageCount);
		}
		const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
		const rightSiblingIndex = Math.min(currentPage + siblingCount, totalPageCount);
		const firstPageIndex = 1;
		const lastPageIndex = totalPageCount;
		const middleRange = range(leftSiblingIndex, rightSiblingIndex);
		return [firstPageIndex, ...middleRange, lastPageIndex];
	}, [totalCount, pageSize, siblingCount, currentPage]);

	return paginationRange;
};
