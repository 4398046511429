import { yupResolver } from '@hookform/resolvers/yup';
import { useAppDispatch } from 'app/hooks';
import loginFormSchema from 'app/schemas/loginForm.schema';
import ButtonComponent from 'components/Button.component';
import LogoIcon from 'components/icons/login.icon';
import TextInputComponent from 'components/TextInput.component';
import { setCredentials } from 'features/authSlice';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLoginMutation } from 'services/auth.service';

interface LoginFormValues {
	login: string;
	password: string;
}

const LoginComponent: React.FC = () => {
	const [login, { isLoading: isLoginLoading }] = useLoginMutation();
	const [customError, setCustomError] = useState<string | null>();
	const dispatch = useAppDispatch();
	const {
		register,
		handleSubmit,
		formState: { errors, isSubmitted },
	} = useForm<LoginFormValues>({
		defaultValues: {},
		resolver: yupResolver(loginFormSchema),
		reValidateMode: 'onChange',
	});

	useEffect(() => {
		const submitHandler = (event: any) => {
			if (event.key === 'Enter') {
				document.getElementById('login-submit')?.click();
			}
		};
		document.addEventListener('keypress', submitHandler);
		return () => {
			document.removeEventListener('keypress', submitHandler);
		};
	}, []);

	const handleSubmitLoginForm = async (data: LoginFormValues): Promise<void> => {
		try {
			setCustomError(null);

			const user = await login({
				login: data.login,
				password: data.password,
			}).unwrap();

			dispatch(setCredentials(user));
		} catch (err) {
			setCustomError('Błąd logowania, sprawdź poprawność danych');
		}
	};

	return (
		<div className="container d-flex align-content-center justify-content-center">
			<div className="login-container">
				<div className="logo">
					<LogoIcon />
				</div>
				<TextInputComponent
					label="Login"
					type="text"
					register={register}
					id="login"
					isSubmited={isSubmitted}
					error={errors.login?.message}
					classes="col-md-6 mb-2"
				/>
				<TextInputComponent
					label="Hasło"
					type="password"
					register={register}
					id="password"
					isSubmited={isSubmitted}
					error={errors.password?.message}
					classes="col-md-6 mb-3"
				/>
				{customError && <div className="custom-from-error">{customError}</div>}
				<ButtonComponent
					id="login-submit"
					title="Zaloguj się"
					isLoading={isLoginLoading}
					onClick={handleSubmit(handleSubmitLoginForm)}
				/>
				{/* <a href="passreminder.html" className="mt-3 text-center">
					Nie pamiętasz hasła?
				</a> */}
			</div>
		</div>
	);
};

export default LoginComponent;
