import { Order, OrderStatusTypes } from 'app/types';
import { get } from 'lodash';
import moment from 'moment';
import { Link } from 'react-router-dom';

interface LastTransactionComponentProps {
	transactions: Order[] | undefined;
}

const LastTransactionComponent: React.FC<LastTransactionComponentProps> = ({
	transactions,
}: LastTransactionComponentProps) => {
	return (
		<>
			<div id="product-list-heading" className="g-0 mb-1 mb-lg-1 d-flex col-sm-12 align-content-center">
				<div className="col-3 col-lg-3">
					<div className="text-muted text-small ps-4">DATA</div>
				</div>
				<div className="col-3 col-lg-2">
					<div className="text-muted text-small">ID TRANSAKCJI</div>
				</div>
				<div className="col-3 col-lg-2">
					<div className="text-muted text-small">SUMA TRANSAKCJI</div>
				</div>
				<div className="col-3 col-lg-2">
					<div className="text-muted text-small">CZAS W SKLEPIE</div>
				</div>
				<div className="col-3 col-lg-2">
					<div className="text-muted text-small">STATUS</div>
				</div>
			</div>

			{transactions &&
				transactions.map((transaction: Order, index: number) => {
					return (
						<div className="card dailysales col-sm-12 ps-0 mb-2" key={`transaction-${index}`}>
							<div className="row col-12 h-100 g-0 position-relative align-content-center">
								<div className="col-12 col-lg-3 d-flex flex-column pe-1 ps-4 mb-2 mb-lg-0 justify-content-center">
									<div className="text-alternate">
										{moment(transaction.createdAt).format('YYYY-MM-DD')} &nbsp;
										<span className="text-small">
											{moment(transaction.createdAt).format('HH:mm')}
										</span>
									</div>
								</div>
								<div className="col-12 col-lg-2 d-flex flex-column pe-1 mb-2 mb-lg-0 justify-content-center">
									<div className="text-alternate">
										<Link to={`/transaction/${transaction._id}`}>
											{get(transaction, 'number', '-')}
										</Link>
									</div>
								</div>
								<div className="col-12 col-lg-2 d-flex flex-column pe-1 mb-2 mb-lg-0 justify-content-center">
									<div className="text-alternate">
										<strong>{get(transaction, 'priceBrutto')}</strong>
									</div>
								</div>
								<div className="col-12 col-lg-2 d-flex flex-column pe-1 mb-2 mb-lg-0 justify-content-center">
									<div className="text-alternate">12min 10s</div>
								</div>
								<div className="col-12 col-lg-2 d-flex flex-column pe-1 mb-2 mb-lg-0 justify-content-center">
									<div className="text-alternate me-auto">
										{get(transaction, 'status') === OrderStatusTypes.COMPLETED && (
											<div className="payment-status bg-outline-green px-3 rounded-pill">
												Zatwierdzona
											</div>
										)}
										{get(transaction, 'status') === OrderStatusTypes.NEW && (
											<div className="payment-status bg-outline-yellow px-3 rounded-pill">
												W trakcie
											</div>
										)}
										{get(transaction, 'status') === OrderStatusTypes.CANCELLED && (
											<div className="payment-status bg-outline-red px-3 rounded-pill">
												Przerwana
											</div>
										)}
									</div>
								</div>
							</div>
						</div>
					);
				})}
		</>
	);
};

export default LastTransactionComponent;
