const ArrowDownIcon = () => (
	<svg width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M1.3001 1.7002L6.7001 7.10019L12.1001 1.7002"
			stroke="#4E4E4E"
			strokeWidth="1.5"
			strokeMiterlimit="3.3333"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);

export default ArrowDownIcon;
