import React, { useEffect } from 'react';
import { useAppSelector, useAppDispatch } from 'app/hooks';
import { clearToast, ToastType } from '../features/appSlice';

const ToastComponent: React.FC = () => {
	const toast = useAppSelector(state => state.app.toast);
	const dispatch = useAppDispatch();

	useEffect(() => {
		if (toast?.type) {
			setTimeout(() => {
				dispatch(clearToast());
			}, 3000);
		}
	}, [toast]);

	if (toast?.type === ToastType.WARNING) {
		return (
			<div className="toastie alert">
				<div className="card">
					<div className="card-body">
						<h5>Wystąpił błąd!</h5>
						<p>{toast.text ? toast.text : 'Wystąpił nieoczekiwany błąd'}</p>
					</div>
				</div>
			</div>
		);
	}

	if (toast?.type === ToastType.SUCCESS) {
		return (
			<div className="toastie success">
				<div className="card">
					<div className="card-body">
						<h5>Zapisano!</h5>
						<p>{toast.text ? toast.text : 'Poprawnie zapisano'}</p>
					</div>
				</div>
			</div>
		);
	}

	return <></>;
};

export default ToastComponent;
