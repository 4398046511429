import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useAppDispatch } from 'app/hooks';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useLazyGetUserQuery, useUpdateUserMutation, useDeleteUserMutation } from 'services/users.service';
import { Order, User, CreateUserRequest, UserRoleTranslation } from 'app/types';
import { get, isArray, isEmpty } from 'lodash';
import { useLazyGetUserLastOrdersQuery } from 'services/orders.service';
import userSchema from 'app/schemas/user.schema';
import TextInputComponent from 'components/TextInput.component';
import SwitchComponent from 'components/Switch.component';
import LastTransactionComponent from 'components/LastTransaction.component';

import listekIcon from 'assets/images/listek.svg';
import saveIcon from 'assets/images/save.svg';
import deleteIcon from 'assets/images/delete.svg';
import backIcon from 'assets/images/back.svg';
import CockpitIcon from 'assets/images/cockpit.svg';
import cardIcon from 'assets/images/card.svg';
import { getConfirmation } from 'app/util';
import { setToast, ToastType } from 'features/appSlice';

const UserComponent: React.FC = () => {
	const { id } = useParams();
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const [user, setUser] = useState<User>();
	const [lastUserTransactions, setLastUserTransactions] = useState<Order[]>();
	const [getLastUserTransactions] = useLazyGetUserLastOrdersQuery();
	const [updateUser] = useUpdateUserMutation();
	const [deleteUser] = useDeleteUserMutation();

	const [getUser] = useLazyGetUserQuery();
	const {
		register,
		handleSubmit,
		setValue,
		formState: { errors, isSubmitted },
	} = useForm<CreateUserRequest>({
		resolver: yupResolver(userSchema),
		defaultValues: {},
		reValidateMode: 'onChange',
	});

	useEffect(() => {
		const loadUser = async () => {
			try {
				if (id) {
					const res = await getUser({ id }).unwrap();

					if (res.success && res.document) {
						setUser(res.document);
						if ('name' in res.document) {
							setValue('name', res.document.name);
						}
						if ('surname' in res.document) {
							setValue('surname', res.document.surname);
						}
						if ('active' in res.document) {
							setValue('active', res.document.active);
						}
						if ('verifiedAdult' in res.document) {
							setValue('verifiedAdult', res.document.verifiedAdult);
						}
						if ('commercialInformationAgreement' in res.document) {
							setValue('commercialInformationAgreement', res.document.commercialInformationAgreement);
						}
						if ('dataProcessingAgreement' in res.document) {
							setValue('dataProcessingAgreement', res.document.dataProcessingAgreement);
						}
					}
				}
			} catch (err) {
				// setIsLoading(false);
			}
		};
		const loadLastUserTransactions = async () => {
			if (id) {
				const res = await getLastUserTransactions({ creator: id, limit: 5, [`sort[createdAt]`]: -1 }).unwrap();
				if (res.success && res.documents) {
					setLastUserTransactions(res.documents);
				}
			}
		};
		loadUser();
		loadLastUserTransactions();
	}, [id]);

	const handleSave = async (formData: CreateUserRequest) => {
		try {
			if (id) {
				const data = { ...formData };
				const res = await updateUser({ id, ...data }).unwrap();

				if (res && res.success) {
					dispatch(setToast({ type: ToastType.SUCCESS }));
				} else {
					dispatch(setToast({ type: ToastType.WARNING }));
				}
			}

			navigate('/users');
		} catch (err) {
			dispatch(setToast({ type: ToastType.WARNING }));
		}
	};

	const handleDelete = async () => {
		try {
			const confirmed = await getConfirmation('Czy chcesz usunąć użytkownika?');

			if (confirmed && id) {
				const res = await deleteUser({ id }).unwrap();

				if (res.success) {
					navigate('/users');
				} else {
					dispatch(setToast({ type: ToastType.WARNING }));
				}
			}
		} catch (err) {
			dispatch(setToast({ type: ToastType.WARNING }));
		}
	};

	return (
		<form onSubmit={handleSubmit(handleSave)}>
			<div className="row g-0">
				<div className="col-sm-6 mb-3 me-auto">
					<div className="w-auto">
						<Link to="/users" className="muted-link pb-1 d-inline-block breadcrumb-back">
							<img src={backIcon} />
							<span className="text-small text-muted align-middle">Użytkownicy</span>
						</Link>
						<h1 className="mb-0 pb-0 " id="title">
							<img src={listekIcon} className="logo-leaf-heading mt-3 me-3" />
							Edytuj użytkownika
						</h1>
					</div>
				</div>
				<div className="col-12 col-sm-2 d-flex align-items-end justify-content-end mb-3 order-sm-3">
					<button
						type="submit"
						className="semigreen btn btn-outline-primary border-0 w-100 px-3 me-3"
						data-bs-toggle="tooltip"
						data-bs-placement="top"
						title="Zapisz"
						data-bs-original-title="Tooltip on top"
					>
						<img src={saveIcon} width="20" height="20" className="m-2" />
					</button>
					<button
						type="button"
						className="semired btn btn-outline-danger border-0 w-100 px-3"
						data-bs-toggle="tooltip"
						data-bs-placement="top"
						title="Usuń użytkownika"
						data-bs-original-title="Tooltip on top"
						onClick={handleDelete}
					>
						<img src={deleteIcon} className="m-2" />
					</button>
				</div>
			</div>

			<div id="customer-details" className="row gx-4 gy-0">
				<div className="col-sm-8 col-md-8 col-sm-12">
					<h5 className="text-alternate mt-3 mb-2 ps-3">Dane</h5>
					<div className="card">
						<div className="card-body">
							<div className="row gx-4 gy-0">
								<div className="col-sm-12 col-md-3">
									<div className="input-group mb-3">
										<TextInputComponent
											label="Imię"
											type="text"
											register={register}
											id="name"
											isSubmited={isSubmitted}
											error={errors.name?.message}
											classes="w-100"
										/>
									</div>
								</div>
								<div className="col-sm-12 col-md-3">
									<div className="input-group mb-3">
										<TextInputComponent
											label="Nazwisko"
											type="text"
											register={register}
											id="surname"
											isSubmited={isSubmitted}
											error={errors.surname?.message}
											classes="w-100"
										/>
									</div>
								</div>
								<div className="col-sm-12 col-md-3">
									<div className="input-group mb-3">
										<label className="form-label text-small ps-2 m-0 w-100">Rola</label>
										<input
											type="text"
											className="form-control w-100"
											disabled
											value={
												UserRoleTranslation[user?.role as keyof typeof UserRoleTranslation] ||
												''
											}
										/>
									</div>
								</div>
							</div>
							<div className="row gx-4 gy-0">
								<div className="col-sm-12 col-md-3">
									<div className="input-group mb-3">
										<TextInputComponent
											label="Hasło"
											type="password"
											register={register}
											id="password"
											isSubmited={isSubmitted}
											error={errors.password?.message}
											classes="w-100"
										/>
									</div>
								</div>
								<div className="col-sm-12 col-md-3">
									<div className="input-group mb-3">
										<TextInputComponent
											label="Powtórz hasło"
											type="password"
											register={register}
											id="passwordConfirm"
											isSubmited={isSubmitted}
											error={errors.passwordConfirm?.message}
											classes="w-100"
										/>
									</div>
								</div>
								<div className="col-sm-12 col-md-3">
									<label className="form-label text-small ps-2">Ustawienia</label>
									<SwitchComponent
										classes="form-check form-switch"
										register={register}
										id="active"
										label="Konto aktywne"
									/>
									<SwitchComponent
										classes="form-check form-switch mt-1"
										register={register}
										id="verifiedAdult"
										label="Konto zweryfikowane"
									/>
								</div>
								<div className="col-sm-12 col-md-3">
									<label className="form-label text-small ps-2"></label>
									<SwitchComponent
										classes="form-check form-switch mt-1"
										register={register}
										id="commercialInformationAgreement"
										label="Zgody marketingowe"
									/>
									<SwitchComponent
										classes="form-check form-switch mt-1"
										register={register}
										id="dataProcessingAgreement"
										label="Przetwarzanie danych"
									/>
								</div>
							</div>
							<div className="row gx-4 gy-0">
								<div className="col-sm-12 col-md-3">
									<div className="input-group mb-3 d-flex flex-wrap">
										<label className="form-label text-small w-100 ps-2">Adres</label>
										<div className="text-alternate col-sm-12 ps-2">
											{get(user, 'address.street', '-')} {get(user, 'address.houseNumber', '-')}
										</div>
										<div className="text-alternate col-sm-12 ps-2">
											{get(user, 'address.postCode', '-')} {get(user, 'address.locality', '-')}
										</div>
									</div>
								</div>
								<div className="col-sm-12 col-md-3">
									<div className="input-group mb-3 d-flex flex-wrap">
										<label className="form-label text-small w-100 ps-2">Kontakt</label>
										{get(user, 'email', '-') && (
											<div className="text-alternate col-sm-12 ps-2">
												{get(user, 'email', '-')}
											</div>
										)}
										{get(user, 'phone', '-') && (
											<div className="text-alternate col-sm-12 ps-2">
												{get(user, 'phone', '-')}
											</div>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="today-sales col-sm-12">
						<h5 className="text-alternate mt-3 mb-2 ps-3">Ostatnie transakcje</h5>
						{!isEmpty(lastUserTransactions) ? (
							<LastTransactionComponent transactions={lastUserTransactions} />
						) : (
							<div className="card">
								<div className="card-body">Brak ostatnich transakcji</div>
							</div>
						)}
					</div>
				</div>

				<div className="col-md-4 col-sm-12">
					<h5 className="text-alternate mt-3 mb-2 ps-3">Płatność</h5>
					<div className="card">
						<div className="card-body">
							<div className="input-group">
								<img
									src={cardIcon}
									className="me-2"
									data-bs-toggle="tooltip"
									data-bs-placement="top"
									title="Karta potwierdzona"
									data-bs-original-title="Tooltip on top"
								/>
								{get(user, 'cardAdded') ? 'Karta zaakceptowana' : 'Brak karty'}
							</div>
						</div>
					</div>
					<h5 className="text-alternate mt-3 ps-3">Sklepy</h5>
					<div className="card">
						<div className="card-body">
							{isArray(user?.shops) && !isEmpty(user?.shops) ? (
								user?.shops.map((shop: { _id: string; name: string }, index: number) => {
									return (
										<div
											className="admin-shop d-flex justify-content-between align-items-center mb-1"
											key={`shop-${index}`}
										>
											<div className="text-alternate green">
												<img src={CockpitIcon} /> {shop.name}
											</div>
										</div>
									);
								})
							) : (
								<div className="text-alternate">
									<img src={CockpitIcon} /> Brak sklepów
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		</form>
	);
};

export default UserComponent;
