import * as yup from 'yup';

export default yup
	.object({
		name: yup.string().required('Pole jest wymagane'),
		code: yup.string().required('Pole jest wymagane'),
		owner: yup.string().length(24).required('Pole jest wymagane'),
		address: yup
			.object({
				postCode: yup.string().required('Pole jest wymagane'),
				locality: yup.string().required('Pole jest wymagane'),
				street: yup.string().required('Pole jest wymagane'),
				houseNumber: yup.string().required('Pole jest wymagane'),
			})
			.required(),
	})
	.required();
