import { useAppSelector } from 'app/hooks';
import { Order } from 'app/types';
import { useEffect, useState } from 'react';
import { useLazyGetLastOrdersQuery } from 'services/cockpit.service';
import { isEmpty } from 'lodash';
import LastTransactionComponent from 'components/LastTransaction.component';

const CockpitComponent: React.FC = () => {
	const shopId = useAppSelector(state => state.app.selectedShop);
	const [lastUserTransactions, setLastUserTransactions] = useState<Order[]>();
	const [getLastTransactions] = useLazyGetLastOrdersQuery();

	useEffect(() => {
		const loadLastTransactions = async () => {
			if (shopId) {
				const res = await getLastTransactions({ shopId, limit: 5, [`sort[createdAt]`]: -1 }).unwrap();

				if (res.success && res.documents) {
					setLastUserTransactions(res.documents);
				}
			}
		};
		loadLastTransactions();
	}, [shopId]);

	return (
		<>
			<div className="today-sales col-sm-12">
				<h5 className="text-alternate mt-3 mb-2 ps-3">Ostatnie transakcje</h5>
				{!isEmpty(lastUserTransactions) ? (
					<LastTransactionComponent transactions={lastUserTransactions} />
				) : (
					<div className="card">
						<div className="card-body">Brak ostatnich transakcji</div>
					</div>
				)}
			</div>
		</>
	);
};

export default CockpitComponent;
