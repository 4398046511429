import { Order, OrderProduct, OrderStatusTypes, UserRoleTypes } from 'app/types';
import listekIcon from 'assets/images/listek.svg';
import backIcon from 'assets/images/back.svg';
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useLazyGetOrderQuery } from 'services/orders.service';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { setIsLoading } from 'features/appSlice';
import moment from 'moment';
import { get, isEmpty } from 'lodash';

export const TransactionDetails: React.FC = () => {
	const { id } = useParams();
	const dispatch = useAppDispatch();
	const user = useAppSelector(state => state.auth.user);
	const [getTransaction] = useLazyGetOrderQuery();
	const [transaction, setTransaction] = useState<Order>();

	useEffect(() => {
		if (id) {
			const loadTransaction = async () => {
				try {
					dispatch(setIsLoading({ isLoading: true }));
					const res = await getTransaction({ id }).unwrap();
					setTransaction(res.document);
					dispatch(setIsLoading({ isLoading: false }));
				} catch (err) {
					dispatch(setIsLoading({ isLoading: false }));
					//Toast Todo
				}
			};
			loadTransaction();
		}
	}, [id]);

	const renderProducts = () => {
		if (!isEmpty(transaction?.products)) {
			return transaction?.products.map((product: OrderProduct) => (
				<div className="card product col-12 ps-0 mb-2 pe-4" key={product.productId}>
					<div className="row col-12 h-100 g-0 position-relative align-content-center">
						<Link to={`/product/${product.productId}`} className="col-1">
							<img
								src={get(product, 'media[0]', '')}
								alt="product"
								className="card-img card-img-horizontal h-100 order-product-thumbnail"
							/>
						</Link>
						<Link
							to={`/product/${product.productId}`}
							className="col-11 col-lg-4 d-flex flex-column mb-lg-0 mb-3 ps-3 pe-3 justify-content-center"
						>
							{product.name}
							<div className="text-small text-muted text-truncate position">EAN: {product.ean}</div>
						</Link>
						<div className="col-12 col-lg-2 d-flex flex-column pe-1 mb-2 mb-lg-0 justify-content-center">
							<div>{product.amount}</div>
						</div>
						<div className="col-12 col-lg-3 d-flex flex-column pe-1 mb-2 mb-lg-0 justify-content-center">
							<div>{product.unitPriceBrutto} zł</div>
						</div>
						<div className="col-12 col-lg-2 d-flex flex-column pe-1 mb-2 mb-lg-0 justify-content-center">
							<div>
								<strong>{product.priceBruttoSum} zł</strong>
							</div>
						</div>
					</div>
				</div>
			));
		}
		return null;
	};

	const getStatusLabel = () => {
		switch (transaction?.status) {
			case OrderStatusTypes.PAYMENT_ISSUED:
			case OrderStatusTypes.NEW:
				return <div className="payment-status bg-outline-yellow px-3 rounded-pill">W trakcie</div>;
			case OrderStatusTypes.COMPLETED:
				return <div className="payment-status bg-outline-green px-3 rounded-pill">Zatwierdzona</div>;
			case OrderStatusTypes.CANCELLED: {
				return <div className="payment-status bg-outline-red px-3 rounded-pill">Przerwana</div>;
			}
			default:
				return null;
		}
	};

	return (
		<>
			<div className="row g-0">
				<div className="col-12 mb-3 me-auto">
					<div className="w-auto ">
						<Link to="/transactions" className="muted-link pb-1 d-inline-block breadcrumb-back">
							<img src={backIcon} />
							&nbsp;
							<span className="text-small align-middle text-muted">Transakcje</span>
						</Link>
						<h1 className="mb-0 pb-0  d-flex flex-nowrap align-items-center" id="title">
							<img src={listekIcon} className="logo-leaf-heading mt-3 me-3" />
							Szczegóły transakcji
						</h1>
					</div>
				</div>
			</div>
			<div className="d-flex flex-wrap mb-0">
				<hr className="w-100" />
			</div>

			<div id="sale-details" className="d-flex flex-wrap gx-4 gy-0">
				<div
					id="inwentaryzacja-list-heading"
					className="g-0 mb-2 mb-lg-2 pe-4 d-flex col-12 align-content-center"
				>
					<div className="col-3 col-lg-3 ps-3">
						<div className="text-muted text-small">TRANSAKCJA</div>
					</div>
					<div className="col-2 col-lg-3 ps-5">
						<div className="text-muted text-small">DATA</div>
					</div>
					<div className="col-2 col-lg-2">
						<div className="text-muted text-small">CZAS TRWANIA</div>
					</div>
					<div className="col-3 col-lg-2">
						<div className="text-muted text-small">ILOŚĆ PRODUKTÓW</div>
					</div>
					<div className="col-3 col-lg-2">
						<div className="text-muted text-small">WYKONAWCA</div>
					</div>
				</div>

				<div className="card sales col-12 ps-0 mb-3 pe-4">
					<div className="d-flex flex-wrap h-100 align-content-center">
						<Link
							to=""
							className="col-11 col-lg-3 d-flex flex-column mb-lg-0 mb-3 ps-3 pe-3 justify-content-center"
						>
							{transaction?.number || '-'}
						</Link>
						<div className="col-12 col-lg-3 d-flex flex-column pe-1 ps-5 py-3 mb-2 mb-lg-0 justify-content-center">
							<div>{moment(transaction?.createdAt).format('DD.MM.YYYY')}</div>
							<div className="text-small text-muted text-truncate position">
								{moment(transaction?.createdAt).format('HH:mm')}
							</div>
						</div>
						<div className="col-12 col-lg-2 d-flex flex-column pe-1 mb-2 mb-lg-0 justify-content-center">
							<div>Dodam jak będą kody</div>
						</div>
						<div className="col-12 col-lg-2 d-flex flex-column pe-1 mb-2 mb-lg-0 justify-content-center">
							<div>{transaction?.products?.length}</div>
						</div>
						<div className="col-12 col-lg-2 d-flex pe-1 mb-2 mb-lg-0 align-items-center flex-wrap">
							<Link
								to={
									user?.role === UserRoleTypes.ADMIN
										? `/users/${get(transaction, 'creator._id', '')}`
										: ''
								}
							>
								{get(transaction, 'creator.name', '-')}
							</Link>
						</div>
					</div>
				</div>
			</div>
			<div className="row gx-4 gy-0">
				<div className="col-sm-8">
					<div
						id="inwentaryzacja-products-heading"
						className="g-0 mb-2 mb-lg-2 pe-4 mt-2 d-flex col-12 align-content-center"
					>
						<div className="col-3 col-lg-1 d-none d-lg-flex"> </div>
						<div className="col-4 col-lg-4 ps-3">
							<div className="text-muted text-small">NAZWA</div>
						</div>
						<div className="col-2 col-lg-2">
							<div className="text-muted text-small">ILOŚĆ</div>
						</div>
						<div className="col-3 col-lg-3">
							<div className="text-muted text-small">WARTOŚĆ JEDNOSTKOWA</div>
						</div>
						<div className="col-3 col-lg-2">
							<div className="text-muted text-small">SUMA</div>
						</div>
					</div>

					{renderProducts()}
				</div>
				<div className="col-sm-4">
					<h5 className="text-alternate mt-0 mb-1 ps-3">Informacje</h5>
					<div className="card">
						<div className="card-body">
							<div className="input-group mb-3">
								<label className="form-label mb-0 text-small w-100">Suma</label>
								<div className="payment-sum">
									<strong>{transaction?.priceBrutto || '-'} zł</strong>
								</div>
							</div>
							<div className="input-group mb-3">
								<label className="form-label mb-0 text-small w-100">Płatność</label>
								{getStatusLabel()}
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
