import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery, ListParams, parseListParams } from 'app/api';
import {
	BaseDocumentResponse,
	BaseListResponse,
	GetUserRequest,
	User,
	UpdateUserRequest,
	BaseSuccessResponse,
	DeleteUserRequest,
	VerifyIdentityRequest,
	Identity,
	GetPhotoRequest,
	VerifyIdentityResponse,
	CreateAdminRequest,
	UpdateAdminRequest,
} from '../app/types';

export const usersApi = createApi({
	baseQuery,
	reducerPath: 'usersApi',
	endpoints: builder => ({
		getUsers: builder.query<BaseListResponse<User>, ListParams>({
			query: params => ({
				url: parseListParams('user/user', params),
				method: 'GET',
			}),
		}),
		getIdentities: builder.query<BaseListResponse<Identity>, ListParams>({
			query: params => ({
				url: parseListParams('user/identityInfo', params),
				method: 'GET',
			}),
		}),
		verifyIdentity: builder.mutation<VerifyIdentityResponse, VerifyIdentityRequest>({
			query: ({ id, ...data }) => ({
				url: `/user/verifyIdentity/${id}`,
				method: 'POST',
				body: data,
			}),
		}),
		updateUser: builder.mutation<BaseDocumentResponse<User>, UpdateUserRequest>({
			query: ({ id, ...data }) => ({
				url: `user/user/${id}`,
				method: 'PUT',
				body: data,
			}),
		}),
		deleteUser: builder.mutation<BaseSuccessResponse, DeleteUserRequest>({
			query: ({ id }) => ({
				url: `user/user/${id}`,
				method: 'DELETE',
			}),
		}),
		getUsersByRole: builder.query<BaseListResponse<User>, string>({
			query: role => ({
				url: `user/user?role=${role}`,
				method: 'GET',
			}),
		}),
		getUser: builder.query<BaseDocumentResponse<User>, GetUserRequest>({
			query: ({ id }) => ({
				url: `user/user/${id}`,
				method: 'GET',
			}),
		}),
		getFrontPhoto: builder.query<Blob, GetPhotoRequest>({
			query: ({ id }) => ({
				url: `/user/getIdentityPhoto/${id}/frontPhoto`,
				method: 'GET',
				responseHandler: response => response.blob(),
			}),
		}),
		getBackPhoto: builder.query<Blob, GetPhotoRequest>({
			query: ({ id }) => ({
				url: `/user/getIdentityPhoto/${id}/backPhoto`,
				method: 'GET',
				responseHandler: response => response.blob(),
			}),
		}),
		createAdmin: builder.mutation<BaseDocumentResponse<User>, CreateAdminRequest>({
			query: data => ({
				url: '/user/admin',
				method: 'POST',
				body: data,
			}),
		}),
		updateAdmin: builder.mutation<BaseDocumentResponse<User>, UpdateAdminRequest>({
			query: ({ id, ...data }) => ({
				url: `/user/admin/${id}`,
				method: 'PUT',
				body: data,
			}),
		}),
	}),
});

export const {
	useUpdateUserMutation,
	useLazyGetUsersByRoleQuery,
	useGetUsersQuery,
	useLazyGetUserQuery,
	useDeleteUserMutation,
	useVerifyIdentityMutation,
	useGetIdentitiesQuery,
	useLazyGetFrontPhotoQuery,
	useLazyGetBackPhotoQuery,
	useCreateAdminMutation,
	useUpdateAdminMutation,
} = usersApi;
