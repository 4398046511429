import _ from 'lodash';
import translations from '../assets/dicts/translations.dict.json';

export const handleApiErrors = (setError: any, err: any) => {
	if (err.data && err.data.message) {
		for (const [key, value] of Object.entries(err.data.message)) {
			if (_.isObject(value)) {
				_.each(value, (val, k) => {
					setError(`${key}.${k}` as any, { message: _.get(translations, `${val}`, val) as string });
				});
			} else {
				setError(key as any, { message: _.get(translations, `${value}`, value) as string });
			}
		}
	}
};

export const translateError = (message: string | undefined) => {
	return _.get(translations, `${message}`, message);
};

export const getConfirmation = (title: string) => {
	return new Promise(resolve => {
		const btn = document.getElementById('confirmBtn');
		if (btn) {
			btn.click();
			const modal = document.getElementById('confirmModal');
			const modalTitle = document.getElementById('confirmModalTitle');
			if (modal && modalTitle) {
				modalTitle.innerText = title;
				const clickHandler = (e: any) => {
					const id = _.get(e, 'target.id', null);
					let result = null;
					if (id === 'confirmAccept') {
						result = true;
					} else if (!modal.classList.contains('show')) {
						result = false;
					}
					if (result !== null) {
						modal.removeEventListener('click', clickHandler);
						modalTitle.innerText = 'Czy jesteś pewny?';
						return resolve(result);
					}
				};
				modal.addEventListener('click', clickHandler);

				const keydownHandler = () => {
					if (!modal.classList.contains('show')) {
						document.removeEventListener('keydown', keydownHandler);
						return resolve(false);
					}
				};
				document.addEventListener('keydown', keydownHandler);
			}
		} else {
			return resolve(false);
		}
	}).catch(() => {
		return false;
	});
};
